import OwlCarousel from 'react-owl-carousel2';
import Slider from 'react-slick';
import { events, maps, maps2, options, options2 } from '../../config';
import { data } from '../../../properties/propSanBeatriz';
import React, { createContext, useCallback, useContext, useRef, useState } from 'react';

const ContextSliderSites = createContext();
export const SectionMap = () => {
	const [idx, setIndex] = useState(0);
	const [indexSite, setIndexSite] = useState(0);
	const ref = useRef(null);
	const sliderGoTo = i => {
		setIndexSite(i);
		setIndex(i);
		ref.current.slickGoTo(i);
	};
	const onChanged = useCallback(e => {
		setIndex(e.page.index == -1 ? 0 : e.page.index);
	}, []);
	return (
		<ContextSliderSites.Provider value={{ indexSite }}>
			<section className='Sites-around' id='section-ubicacion'>
				<img className='globo-10 globe-left' src={require('../../../Assets/images/landing/globo-5.png')}></img>
				<div className='content-title idx-content-title-map'>
					<span className='title title-1 text-center d-block '>
						VIVE CERCA 
						<img className='img' src={require('../../../Assets/images/inicio/underline/a-todo.png')}></img>
					</span>
				</div>
				<div className='idx_mobile flex items-center gap-1 justify-center mb-2'>
					<img className='idx_icon_place' src={require('../../../Assets/images/inicio/main/icon-place.png')}></img>
					<span className=''>Ca. Enrique Villar 830, Santa Beatriz</span>
				</div>
				<div className='content-sites-around'>
					<div className='content-map' href={require('../../../Assets/images/inicio/mapa/mapa/all-sites.jpg')}>
						{data.maps.map((item, index) => {
							return <img key={index} className={'imagen-map img-' + item.name} src={item.img}></img>;
						})}
					</div>
					<div className='content-maps'>
						<div className='button-maps'>
							<div className='button-maps'>
								{data.sites.map((item, index) => {
									return (
										<div
											className={`button button-${index} ${item.color} ${index == idx ? 'active' : ''}`}
											onClick={() => {
												sliderGoTo(index);
											}}
											key={index + 'button'}
										>
											<div className={`icon mask ${item.icono}`}></div>
										</div>
									);
								})}
							</div>
						</div>
						<Slider {...maps2} ref={ref} className='slider-list-site'>
							{data.sites.map((item, index) => {
								return (
									<div key={index} className='content-slide-quality'>
										<div className='slide-site'>
											<div className='content-img'>
												<div className={'content-icon ' + item.color}>
													<div className={'icon ' + item.icono}></div>
													<div className={`title-site ${item.color}`}>{item.name}</div>
												</div>
											</div>
											<div className='info-quality'>
												{item.leyenda.map((paragraph, index) => {
													return (
														<p key={index} className='paragraph'>
															{paragraph}
														</p>
													);
												})}
											</div>
										</div>
									</div>
								);
							})}
						</Slider>
					</div>
				</div>
				<div className='b-white content-owlcarousel ix_content_owl_carousel'>
					<SliderComponent images={data.sites} onChanged={onChanged}></SliderComponent>
					<div className='title-slider w-full mx-auto'>
						<TitleSlider data={data.gallery} idx={idx} />
					</div>
					<div className='idx_point'>
						{data.gallery.map((item, index_site) => {
							return <div className={`dot ${idx === index_site ? 'active' : ''}`} key={index_site + 'idx'}></div>;
						})}
					</div>
				</div>
			</section>
		</ContextSliderSites.Provider>
	);
};

const SliderComponent = React.memo(({ images, onChanged }) => (
	<OwlCarousel options={options2} events={{ onChanged }} className='owl-carousel MyCarouselHorizontal owl-theme owl-loaded owl-drag' id='carousel-interiores'>
		{data.gallery.map((item, index) => {
			return (
				<div className='content-slide-interiores ' data-src={item.img} href={item.imgDesktop} data-sub-html={'#interior-' + index} key={index + 'slider'}>
					<div className='slide-interiores'>
						<div className='content-img'>
							<picture className='expand-img'>
								<source media='(min-width: 740px)' srcset={item.img}></source>
								<img src={item.img}></img>
							</picture>
						</div>
						<div className='idx_mobile idx_sub_title'>
							<img className='idx_part-top' src={require('../../../Assets/images/icons/icon-part-top.svg').default}></img>
							{images[index].icon && <div className={`icon mask ${images[index].icon}`}></div>}
							{item.title}
						</div>
					</div>
				</div>
			);
		})}
	</OwlCarousel>
));

const TitleSlider = ({ data, idx }) => {
	const { indexSite } = useContext(ContextSliderSites);
	return <span className='title text-white'>{data[idx]?.title || ''}</span>;
};
