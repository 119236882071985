import React, { Component, useRef } from 'react'
import './Avancedeobra.scss'
import 'lightgallery/dist/css/lightgallery.css';
import 'lightgallery/dist/js/lightgallery';
import 'lightgallery/dist/js/lightgallery-all';
import $ from "jquery";
import OwlCarousel from "react-owl-carousel2"
const events = {
    onChanged: function(e){
    }
};
const options = {
    items: 1,
    rewind: false,
    loop:false,
    nav:true,
    center:false,
    speed:1000,
    smartSpeed:300,
    dots:true,
    autoWidth:true,
};

const configModal = {
    selector: '.content-step',
    thumbnail:true,
    animateThumb: false,
    showThumbByDefault: false,
    subHtmlSelectorRelative: true,
    rotate:false,
    actualSize:false,
    download:true,
    pinterest:false,
    googlePlus:false,
    twitter:false,
}
export default class Avancedeobra extends Component {
    constructor(props){
        super(props)
        console.log(props)
        this.afterChange = this.afterChange.bind(this)
        this.state = {
            settings:{
                dots: false,
                arrows:false,
                infinite: false,
                speed: 500,
                autoplay: false,
                autoplaySpeed: 3000,
                slidesToShow: 1,
                slidesToScroll: 1,
                variableWidth: true,
                afterChange:this.afterChange
            },  
            value:[
                
                {
                    title:'Columnas que guardan historias',
                    images:[
                        {img: require('../../Assets/images/avance/1.jpg'),},
                        {img: require('../../Assets/images/avance/2.jpg'),},
                        {img: require('../../Assets/images/avance/3.jpg'),},
                        {img: require('../../Assets/images/avance/4.jpg'),},
                    ]
                },
                {
                    title:'A medio camino de cumplir tus sueños',
                    images:[
                        {img: require('../../Assets/images/avance/junio-2022/Helio - Avace de obra Junio 1.jpg'),},
                        {img: require('../../Assets/images/avance/junio-2022/Helio - Avace de obra Junio 2.jpg'),},
                        {img: require('../../Assets/images/avance/junio-2022/Helio - Avace de obra Junio 3.jpg'),},
                    ]
                },
                {
                    title:'Desde aquí arriba se puede ver tu futuro',
                    images:[
                        {img: require('../../Assets/images/avance/junio-2022/Helio - Avace de obra Junio 4.jpeg').default,},
                        {img: require('../../Assets/images/avance/junio-2022/Helio - Avace de obra Junio 5.jpg'),},
                        {img: require('../../Assets/images/avance/junio-2022/Helio - Avace de obra Junio 6.jpg'),},
                        {img: require('../../Assets/images/avance/junio-2022/Helio - Avace de obra Junio 7.jpg'),},
                        {img: require('../../Assets/images/avance/junio-2022/Helio - Avace de obra Junio 8.jpg'),},
                        {img: require('../../Assets/images/avance/junio-2022/Helio - Avace de obra Junio 9.jpg'),},
                        {img: require('../../Assets/images/avance/junio-2022/Helio - Avace de obra Junio 10.jpg'),},
                        {img: require('../../Assets/images/avance/junio-2022/Helio - Avace de obra Junio 11.jpg'),},
                    ]
                },
                {
                    title:'Tan grande como tus sueños',
                    images:[
                        {img: require('../../Assets/images/avance/agosto2022/8.jpg'),},
                        {img: require('../../Assets/images/avance/agosto2022/1.jpg'),},
                        {img: require('../../Assets/images/avance/agosto2022/2.jpg'),},
                        {img: require('../../Assets/images/avance/agosto2022/3.jpg'),},
                        {img: require('../../Assets/images/avance/agosto2022/4.jpg'),},
                        {img: require('../../Assets/images/avance/agosto2022/5.jpg'),},
                        {img: require('../../Assets/images/avance/agosto2022/6.jpg'),},
                        {img: require('../../Assets/images/avance/agosto2022/7.jpg'),},
                    ]
                },
                {
                    title:'Elevamos tus sueños',
                    images:[
                        {img: require('../../Assets/images/avance/noviembre2022/Helio - Foto Avace de obra 1.jpeg').default,},
                        {img: require('../../Assets/images/avance/noviembre2022/Helio - Foto Avace de obra 2.jpeg').default,},
                        {img: require('../../Assets/images/avance/noviembre2022/Helio - Foto Avace de obra 3.jpeg').default,},
                        {img: require('../../Assets/images/avance/noviembre2022/Helio - Foto Avace de obra 4.jpeg').default,},
                        {img: require('../../Assets/images/avance/noviembre2022/Helio - Foto Avace de obra 5.jpeg').default,},
                    ]
                },
                {
                    title:'Todo está listo para mudarte',
                    images:[
                        {img: require('../../Assets/images/avance/enero2023/img-1.jpg'),},
                        {img: require('../../Assets/images/avance/enero2023/img-2.jpg'),},
                        {img: require('../../Assets/images/avance/enero2023/img-3.jpg'),},
                        {img: require('../../Assets/images/avance/enero2023/img-4.jpg'),},
                        {img: require('../../Assets/images/avance/enero2023/img-5.jpg'),},
                        {img: require('../../Assets/images/avance/enero2023/img-6.jpg'),},
                        {img: require('../../Assets/images/avance/enero2023/img-7.jpg'),},
                        {img: require('../../Assets/images/avance/enero2023/img-8.jpg'),},
                    ]
                },
            ],
            height:'21rem',
        }
    }
    afterChange(e,position){
        // this.slider.slickGoTo(position)
    }
   

    componentDidMount(){
        $(".link").removeClass("active")
        $("#avance-de-obra").addClass("active")
        $('.MyCarouselHorizontal').lightGallery(configModal);
    }
    render() {
    return (
      <main className='avance'>
          
          <div className='background'>
            <picture className="expand-img">
                <source media="(min-width: 650px)" srcset={require('../../Assets/images/avance/background.png')}></source>
                <img src={require('../../Assets/images/avance/slider.png')} ></img>
            </picture>
          </div>
           <main className="content-avance">
          <div className="content-title">
                <img className='globe-1' src={require('../../Assets/images/avance/glove-1.png')} ></img>          
                <span className="title title-1 text-center d-block">AVANCE DE OBRA</span>
                <img className='globe-2' src={require('../../Assets/images/avance/glove-2.png')} ></img>          
            </div>
            {
                this.state.value.map((item,index)=>{
                    return(
                         <AvanceItem item={item} index={index}></AvanceItem>
                    )
                })
            }
           
        </main>
      </main>
    )
  }
}

function AvanceItem(props){
    const [height,setHeight] = React.useState('21rem')
    const [alter,setAlter] = React.useState(true)
    const [slider,setSliderRef] = React.useState(null)

    const desplegate=(index)=>{
        console.log(index)
        try {
            let height =  document.querySelector(`.show-${index}`).clientHeight
            setHeight(height);
            setAlter(!alter)
        } catch (error) {
            
        }
    }
    return(
    <>
        <div className='d-flex container'>
            <p className='paragraph'> <span className='matarani-bold'>{props.item.title}</span> <img className={`signal-down ${alter == true ? "active" : ''}`} onClick={()=>{desplegate(props.index)}} src={require("../../Assets/images/icons/signal-down.svg").default}></img></p>
        </div>
        <div className='container container-slider'>
            <div className='slider-steps' style={{height:alter == true ? height : 0}}>
                <div className='shadow'>
                </div>
                <div className={`show show-${props.index}`}>
                    <div className={`navigate navigate-right ${alter == true ? 'active':'' }`}>
                       
                        <div className='nav nav-right' onClick={()=>{slider?.next()}}>
                            <div className='envolve'>
                                <div className='mask icon-right'></div>
                            </div>
                        </div>
                    </div>
                    <div className={`navigate navigate-left ${alter == true ? 'active':''}`}>
                        <div className='nav nav-left' onClick={()=>{slider?.prev()}}>
                            <div className='envolve'>
                                <div className='mask icon-left' ></div>
                            </div>
                        </div>
                    </div>
                    <OwlCarousel ref={car=> (setSliderRef(car))} options={options} events={events} className="owl-carousel MyCarouselHorizontal owl-theme owl-loaded owl-drag" id="carousel-comunes">

                    {
                        props.item.images.map((obj)=>{
                            return(
                                <div class="content-step" href={obj.img}>
                                    <div className='step'>   
                                        <div class="content-imagen">
                                            <img class="imagen"  src={obj.img}/>            
                                        </div>
                                    </div>
                                    
                                </div>
                            )
                        })
                    }
                    </OwlCarousel>
                </div>
            </div>
        </div>
    </>   
    )
}