export const slider = [
	{
		img: require('../../Assets/images/inicio/main/santabeatriz/slider/compress-mobile.webp'),
		desktop: require('../../Assets/images/inicio/main/santabeatriz/slider/compress-desktop.webp'),
	},
	{
		img: require('../../Assets/images/inicio/main/santabeatriz/slider/slider-mobile-2x.png'),
		desktop: require('../../Assets/images/inicio/main/santabeatriz/slider/compress-banner_more90depas.webp'),
	},
	{
		img: require('../../Assets/images/inicio/main/santabeatriz/slider/slider-mobile-3x.png'),
		desktop: require('../../Assets/images/inicio/main/santabeatriz/slider/compress-banner_visita.webp'),
	},
];
