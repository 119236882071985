import React, { Component } from 'react';
// import { HashLink as Link } from "react-router-hash-link"
import './navbar.scss';
import { Link } from 'react-router-dom';
import $ from '../../library/library';
import { connect } from 'react-redux';
import brochure from '../../Assets/pdf/Brochure - Helio Santa Beatriz.pdf';
var navLinks = [
	{
		section: 'cercado-de-lima/#page-inicio',
		signal: '/cercado-de-lima/#menu-inicio',
		class: 'active',
		nav: false,
	},
	{
		section: 'santa-beatriz/#section-interiores',
		signal: '.section-interiores',
		class: 'active',
		nav: false,
	},
	{
		section: 'cercado-de-lima/#section-tipologias',
		signal: '#Tipologias',
		class: 'active',
		nav: false,
	},
	{
		section: 'cercado-de-lima/#section-ubicacion',
		signal: '#Ubicacion',
		class: 'active',
		nav: false,
	},
	{
		section: 'cercado-de-lima/#section-metro-de-lima',
		signal: '#Metro-de-lima',
		class: 'active',
		nav: false,
	},
	{
		section: 'cercado-de-lima/#section-contacto',
		signal: '#Contacto',
		class: 'active',
		nav: false,
	},
	{
		section: 'cercado-de-lima/#page-video',
		signal: '#play-video',
		class: 'active',
		nav: false,
	},
];
class NavbarSantaBeatriz extends Component {
	constructor(props) {
		super(props);
		this.changePage = this.changePage.bind(this);
		this.state = {
			showMenu: false,
			init: 'OFF',
		};
	}
	componentDidMount() {
		function activeLink(options) {
			try {
				let navbarHeight = document.querySelector('#Navbar').offsetHeight; //LA ALTURA DEL NAVBAR
				options.forEach(options => {
					let signalElement = document.querySelector(options.section);

					if (signalElement) {
						if (
							$(options.section).offset().top + (options.nav == true ? navbarHeight : (options.nav = 0)) <= window.scrollY + navbarHeight &&
							$(options.section).offset().top + $(options.section).height() > window.scrollY + navbarHeight
						) {
							document.querySelector(options.signal).classList.add(options.class);
						} else {
							document.querySelector(options.signal).classList.remove(options.class);
						}
					}
				});
			} catch (err) {}
		}
		$().windowScroll(function () {
			activeLink(navLinks);
		});
	}
	toggleMenu() {
		this.setState({ showMenu: !this.state.showMenu });
	}
	aboutUs() {
		this.setState({ showMenu: !this.state.showMenu });
	}
	moveTo(item, lineheight) {
		let navbarHeight = document.querySelector('#Navbar').offsetHeight;
		window.scrollTo(0, document.querySelector(item).offsetTop - navbarHeight + lineheight);
		let delay = setInterval(() => {
			this.setState({ showMenu: false });
			clearInterval(delay);
		}, 100);
		// if (window.location.pathname.length <= 1) {
		// } else {
		// 	let delay = setInterval(() => {
		// 		this.setState({ showMenu: false });
		// 		clearInterval(delay);
		// 	}, 100);
		// 	window.location.href = '/' + navLinks[item].section;
		// }
	}
	changePage() {
		this.setState({ showMenu: false });
	}
	render() {
		const facebook =
			'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.61 20.68"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path id="facebook" d="M2.12,10.42v10a.26.26,0,0,0,.26.26H6.09a.26.26,0,0,0,.26-.26V10.26H9A.26.26,0,0,0,9.3,10L9.56,7a.26.26,0,0,0-.26-.28h-3V4.5a.92.92,0,0,1,.92-.92H9.35a.26.26,0,0,0,.26-.26V.26A.26.26,0,0,0,9.35,0H5.84A3.73,3.73,0,0,0,2.12,3.72v3H.26A.26.26,0,0,0,0,6.94V10a.26.26,0,0,0,.26.26H2.12Z" style="fill:#78787e;fill-rule:evenodd"/></g></g></svg>';
		const instagram =
			'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.57 21.57"><g id="Capa_2" data-name="Capa 2"><g id="Capa_1-2" data-name="Capa 1"><path id="instagram" d="M16.46,4a1.27,1.27,0,1,0,1.27,1.27A1.27,1.27,0,0,0,16.46,4M10.82,5.6a5.33,5.33,0,1,0,5.33,5.33A5.34,5.34,0,0,0,10.82,5.6m0,8.74a3.42,3.42,0,1,1,3.42-3.41,3.41,3.41,0,0,1-3.42,3.41M21.57,6.5A6.5,6.5,0,0,0,15.07,0H6.5A6.5,6.5,0,0,0,0,6.5v8.57a6.5,6.5,0,0,0,6.5,6.5h8.57a6.5,6.5,0,0,0,6.5-6.5Zm-2,8.57a4.47,4.47,0,0,1-4.47,4.47H6.5A4.46,4.46,0,0,1,2,15.07V6.5A4.45,4.45,0,0,1,6.5,2h8.57A4.46,4.46,0,0,1,19.54,6.5Z" style="fill:#78787e"/></g></g></svg>';
		return (
			<nav className={`Navbar ${this.props.value.navShow == true ? '' : 'none'}`} id='Navbar'>
				<div className='brand'>
					<a href='/' className='casaideal'>
						<div className='icon-helio'></div>
					</a>
				</div>
				<div className='content-links'>
					<div className='link link-toggle'>
						<div className={this.state.showMenu ? 'toggle ON' : `toggle ${this.state.init}`} onClick={this.toggleMenu.bind(this)}>
							<div className='barra barra-1'></div>
							<div className='barra barra-2'></div>
							<div className='barra barra-3'></div>
						</div>
					</div>
					<a className='link'>
						<i className='icono instagram' dangerouslySetInnerHTML={{ __html: instagram }}></i>
					</a>
					<div className='link'></div>
					<a className='link proyecto'>Proyectos en venta</a>
				</div>
				<div className={this.state.showMenu ? 'Menu ON' : `Menu ${this.state.init}`}>
					<div className='hidden-m'>
						<div className='content-link-menu'>
							<div className='menu-links'>
								<div className='part-left'>
									<div
										id='menu-inicio'
										className='link active d-none-desk'
										onClick={() => {
											this.moveTo(0);
										}}
									>
										Inicio<span className='barra'></span>
									</div>
									<div
										id='estilo-de-vida'
										className='link'
										onClick={() => {
											this.moveTo('.section-recorrido', 10);
										}}
									>
										Explora tu futuro<span className='barra'></span>
									</div>
									<div
										id='Interiores'
										className='link'
										onClick={() => {
											this.moveTo('.section-interiores', 100);
										}}
									>
										Interiores<span className='barra'></span>
									</div>
									<div
										id='Tipologias'
										className='link'
										onClick={() => {
											this.moveTo('#section-tipologias', 100);
										}}
									>
										Tipologías<span className='barra'></span>
									</div>
									<div
										id='Ubicacion'
										className='link'
										onClick={() => {
											this.moveTo('#section-ubicacion', 10);
										}}
									>
										Ubicación<span className='barra'></span>
									</div>
									{/* <a href='/avance-de-obra' id='avance-de-obra' className='link'>
										Avance de obra<span className='barra'></span>
									</a> */}
								</div>
								<div className='part-right'>
									{/* <div
										id='Metro-de-lima'
										className='link'
										onClick={() => {
											this.moveTo(5);
										}}
									>
										Metro de Lima<span className='barra'></span>
									</div> */}
									<div
										id='Contacto'
										className='link'
										onClick={() => {
											this.moveTo('.Contacto', 10);
										}}
									>
										Contacto<span className='barra'></span>
									</div>
									<a href={brochure} download={true} className='link' id='Nosotros'>
										Brochure<span className='barra'></span>
									</a>
									<a href={'/cercado-de-lima'} className='link white-space' id='Nosotros'>
										Proyecto entregado<span className='barra'></span>
									</a>
								</div>
								<div id='vende' className='link content-redes'>
									<p className='text-center'>Síguenos en</p>
									<div className='redes'>
										<a href='https://www.facebook.com/ProyectoHelio' target='__blank' className='d-block link-out'>
											<span className='d-block icon nav-facebook'></span>
										</a>
										<a href='https://www.instagram.com/proyectohelio/' target='__blank' className='d-block link-out'>
											<span className='d-block icon nav-instagram'></span>
										</a>
										<a className='d-block link-out' href='https://www.youtube.com/channel/UC98Rz1EFRMCDX7qmksmSdLQ' target='__blank'>
											<span className='d-block icon nav-youtube'></span>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</nav>
		);
	}
}
const MapStateProps = state => {
	return {
		value: state.routesFeatures,
	};
};
export default connect(MapStateProps)(NavbarSantaBeatriz);
