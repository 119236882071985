import React from 'react'
import { Formik } from 'formik'

export const FormContainer = 
({initialValues, validationSchema, onSubmit, children}) => (
    <Formik
        initialValues={initialValues}
        validate={validate(validationSchema)}
        onSubmit={onSubmit}>
        {children}
    </Formik>
)

export function validate(getValidationSchema) {
    return (values) => {
        const validationSchema = getValidationSchema(values)
        try {
            validationSchema.validateSync(values, {abortEarly: false})
            return {}
        } catch (error) {
            return getErrorsFromValidationError(error)
        }
    }
}

export const handleChecked = (e, form) => {
    form.setFieldValue(e.target.name, e.target.checked)
}

export const setInputProps = (name, classes = "", {values, errors, touched, handleChange, handleBlur}) => ({
    name: name,
    className: `${classes} ${errors[name] && touched[name] ? "--invalid" : values[name] && "--valid"}`,
    onChange: handleChange,
    onBlur: handleBlur
})

export const checkableBoolProps = (name, classes = "", {values, errors, touched, handleBlur, setFieldValue}) => ({
    name: name,
    className: `${classes} ${errors[name] && touched[name] ? "--invalid": values[name] && "--valid"}`,
    onChange: ({target}) => setFieldValue(name, target.checked),
    onBlur: handleBlur
})

function getErrorsFromValidationError(validationError) {
    const FIRST_ERROR = 0
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR],
      }
    }, {}) 
}