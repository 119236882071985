import React, { Component } from 'react'
import './video.scss'
import sr from '../../../components/scrollreveal/scroll'
export default class PonlePlay extends Component {
    componentDidMount = () => {
        // window.scrollTo(0,0)
        function right(el){
            el.classList.add("animation-right")
        }
        function left(el){
            el.classList.add("animation-left")
        }
        sr.reveal(".globo-1",{opacity:1, beforeReveal: left})
        sr.reveal(".globo-2",{opacity:1, beforeReveal: right})
        sr.reveal(".globo-3",{opacity:1, beforeReveal: right})
        sr.reveal(".globo-4",{opacity:1, beforeReveal: left})
        sr.reveal(".globo-6",{opacity:1, beforeReveal: left})
        sr.reveal(".globo-5",{opacity:1, beforeReveal: right})
        sr.reveal(".globo-7",{opacity:1, beforeReveal: left})
        sr.reveal(".globo-8",{opacity:1, beforeReveal: right})
        sr.reveal(".globo-9",{opacity:1, beforeReveal: right})
        sr.reveal(".globo-10",{opacity:1, beforeReveal: left})
        sr.reveal(".globo-11",{opacity:1, beforeReveal: left})
    }
    render() {
        return (
            <div className="page-video" id="page-video">
                <img className="globo-1 globe-left" src={require("../../../Assets/images/landing/globo-5.png")}></img>
                    <img className="globo-2 globe-right" src={require("../../../Assets/images/landing/globo-8.png")}></img>
                    <img className="globo-3 globe-right" src={require("../../../Assets/images/landing/globo-9.png")}></img>
                    <img className="globo-4 globe-left" src={require("../../../Assets/images/landing/globo-12.png")}></img>
                <h1 className="title">
                    <span className="line">PONLE PLAY </span>
                    <span className="line line-2">Y PREPÁRATE PARA<span className="Mix-Diary c-white">elevar</span></span>
                    <span className="line">TU ESTILO DE VIDA</span>
                </h1>
                <div className="content-video">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/ygzCoSTt4O8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <img className="background desktop" src={require("../../../Assets/images/Pagevideo/background.png")}></img>
                <img className="background-family desktop" src={require("../../../Assets/images/inicio/iconos/pareja.png")}></img>
                <img className="background movil" src={require("../../../Assets/images/Pagevideo/background-movil.png")}></img>
            </div>
        )
    }
}
