const inicio = {
	sliderMain: [
		// {img:require("../Assets/images/inicio/slider/mobile/slider-1-03-2023-mobile.png"),desktop:require("../Assets/images/inicio/slider/web/slider-1-03-2023-web.png")},
		// {img:require("../Assets/images/inicio/slider/apoderate-movil-2.png"),desktop:require("../Assets/images/inicio/slider/web/apoderate-slider.png")},
		// {img:require("../Assets/images/inicio/slider/mobile/slider-mobile-depa-piloto.png"),desktop:require("../Assets/images/inicio/slider/web/slider-web-depa-piloto.png")},
		{ img: require('../Assets/images/inicio/slider/slider-1.png'), desktop: require('../Assets/images/inicio/slider/web/slider-happy.png') },
		{ img: require('../Assets/images/inicio/slider/slider-2.png'), desktop: require('../Assets/images/inicio/slider/web/Slider-2-desktop.png') },
		{ img: require('../Assets/images/inicio/slider/slider-3.png'), desktop: require('../Assets/images/inicio/slider/web/sliders-baby-shower.png') },
		{ img: require('../Assets/images/inicio/slider/slider-4.png'), desktop: require('../Assets/images/inicio/slider/web/Slider-4-desktop.png') },
	],
	estiloDeVida: [
		{
			class: 'secondary',
			subtitle: 'CERCA A',
			title: 'UNIVERSIDADES',
			paragraph: ' Vive a menos de 100 m. de la Universidad Católica y la UNMSM',
			img: require('../Assets/images/inicio/iconos/study.png'),
		},
		{
			class: 'c-yellow',
			subtitle: 'FRENTE AL',
			title: 'METRO DE LIMA',
			paragraph: 'La Línea 2 conectará Lima. Podrás llegar del Callao a Ate en 45 mins.',
			img: require('../Assets/images/inicio/iconos/estacion.png'),
		},
		{
			class: 'c-green',
			subtitle: 'CERCANÍA A',
			title: 'PARQUES',
			paragraph: ' Más de 18000 m2 de áreas verdes. Parque Urubamba y parque Felipe',
			img: require('../Assets/images/inicio/iconos/parques.png'),
		},
		{
			class: 'c-primary',
			subtitle: 'ESPACIOS DE',
			title: 'TELETRABAJO',
			paragraph: 'Diseñados para que puedas laborar comodamente de manera remota.',
			img: require('../Assets/images/inicio/iconos/teletrabajo.png'),
		},
	],
	interiores: [
		{
			title: 'Dormitorio Principal',
			img: require('../Assets/images/inicio/slider/interiores/interior-dorm-principal.png'),
			imgmovil: require('../Assets/images/inicio/slider/interiores/interior-dorm-principal.png'),
			imgDesktop: require('../Assets/images/inicio/slider/interiores/interior-dorm-principal.png'),
		},
		{
			title: 'Baño',
			img: require('../Assets/images/inicio/slider/interiores/interior-baño.png'),
			imgmovil: require('../Assets/images/inicio/slider/interiores/interior-baño.png'),
			imgDesktop: require('../Assets/images/inicio/slider/interiores/interior-baño.png'),
		},
		{
			title: 'Cocina',
			img: require('../Assets/images/inicio/slider/interiores/interior-cocina.png'),
			imgmovil: require('../Assets/images/inicio/slider/interiores/interior-cocina.png'),
			imgDesktop: require('../Assets/images/inicio/slider/interiores/interior-cocina.png'),
		},
		{
			title: 'Dormitorio Secundario',
			img: require('../Assets/images/inicio/slider/interiores/interior-dorm-secundario.png'),
			imgmovil: require('../Assets/images/inicio/slider/interiores/interior-dorm-secundario.png'),
			imgDesktop: require('../Assets/images/inicio/slider/interiores/interior-dorm-secundario.png'),
		},

		{
			title: 'Sala comedor',
			img: require('../Assets/images/inicio/slider/interiores/interior-sala-comedor.png'),
			imgmovil: require('../Assets/images/inicio/slider/interiores/interior-sala-comedor.png'),
			imgDesktop: require('../Assets/images/inicio/slider/interiores/interior-sala-comedor.png'),
		},
	],
	comunes: [
		{
			title: 'Lobby',
			icon: 'lobby',
			img: require('../Assets/images/inicio/slider/comunes/Loby-2.jpg'),
			imgmovil: require('../Assets/images/inicio/slider/comunes/Loby-2.jpg'),
			imgDesktop: require('../Assets/images/inicio/slider/comunes/Loby-2.jpg'),
		},
		{
			title: 'Sala de niños',
			icon: 'room-of-children',
			img: require('../Assets/images/inicio/slider/comunes/Sala-de-ninos.jpg'),
			imgmovil: require('../Assets/images/inicio/slider/comunes/Sala-de-ninos.jpg'),
			imgDesktop: require('../Assets/images/inicio/slider/comunes/Sala-de-ninos.jpg'),
		},
		{
			title: 'Salón de usos múltiples',
			icon: 'salon-de-usos-multiples',
			img: require('../Assets/images/inicio/slider/comunes/solon-de-usos-multiples.jpg'),
			imgmovil: require('../Assets/images/inicio/slider/comunes/solon-de-usos-multiples.jpg'),
			imgDesktop: require('../Assets/images/inicio/slider/comunes/solon-de-usos-multiples.jpg'),
		},
		{
			title: 'Terraza',
			icon: 'terraza',
			img: require('../Assets/images/inicio/slider/comunes/Terraza-1.jpg'),
			imgmovil: require('../Assets/images/inicio/slider/comunes/Terraza-1.jpg'),
			imgDesktop: require('../Assets/images/inicio/slider/comunes/Terraza-1.jpg'),
		},
		{
			title: 'Terraza lounge',
			icon: 'terraza-lounge',
			img: require('../Assets/images/inicio/slider/comunes/Terraza-Launge.jpg'),
			imgmovil: require('../Assets/images/inicio/slider/comunes/Terraza-Launge.jpg'),
			imgDesktop: require('../Assets/images/inicio/slider/comunes/Terraza-Launge.jpg'),
		},
		{
			title: 'Zona de parrillas',
			icon: 'zona-de-parrillas',
			img: require('../Assets/images/inicio/slider/comunes/Zona-de-Parrillas.jpg'),
			imgmovil: require('../Assets/images/inicio/slider/comunes/Zona-de-Parrillas.jpg'),
			imgDesktop: require('../Assets/images/inicio/slider/comunes/Zona-de-Parrillas.jpg'),
		},
		// {
		//     title:"Zona de parrillas",
		//     icon:"zona-de-parrillas",
		//     img:require("../Assets/images/inicio/slider/comunes/terraza-final-2.jpg"),
		//     imgmovil:require("../Assets/images/inicio/slider/comunes/movil/terraza-final-2-movil.jpg"),
		//     imgDesktop:require("../Assets/images/inicio/slider/comunes/terraza-final-2.jpg"),
		// },
	],

	maps: [
		{ name: '0 active', img: require('../Assets/images/inicio/mapa/mapa/centro-de-estudios.jpg') },
		{ name: '1', img: require('../Assets/images/inicio/mapa/mapa/centros-comerciales.jpg') },
		{ name: '2', img: require('../Assets/images/inicio/mapa/mapa/financieras.jpg') },
		{ name: '3', img: require('../Assets/images/inicio/mapa/mapa/establecimientos-comerciales.jpg') },
		{ name: '4', img: require('../Assets/images/inicio/mapa/mapa/parques.jpg') },
		{ name: '5', img: require('../Assets/images/inicio/mapa/mapa/salud.jpg') },
		{ name: '6', img: require('../Assets/images/inicio/mapa/mapa/linea-2.jpg') },
	],
	sites: [
		{
			icono: 'study',
			color: 'blue',
			leyenda: [
				'1. Universidad Mayor de San Marcos',
				'2. Universidad Católica del Perú',
				'3. UPC',
				'4. Universidad Federico Villareal',
				'5. Zegel IPAE',
				'6. PRE-UNMSM',
				'7. Universidad Privada del Norte ',
				'8. Colegio Trilce',
				'9. Colegio Cristo Rey',
				'10. Colegio Simón Bolívar',
				'11. Colegio Santa María Mazzarello',
				'12. Colegio Hipólito Unanue',
			],
		},
		{
			icono: 'globes',
			color: 'b-secondary',
			leyenda: ['13. C.C. Elio', '14. C.C. Plaza San Miguel'],
		},
		{
			icono: 'icon_money',
			color: 'b-primary',
			leyenda: ['15. BCP', '16. Banco Pichincha', '17. BCP', '18. Agente BCP - Mercado Venezuela'],
		},
		{
			icono: 'buy',
			color: 'sky',
			leyenda: [
				'19. Grifo',
				'20. Mass',
				'21. Farmacia',
				'22. Panadería',
				'23. Mass',
				'24. Mass Universitaria',
				'26. Metro La Marina',
				'27. Mass',
				'28. Plaza Vea Pueblo Libre',
				'29. Metro Fauccet',
			],
		},
		{
			icono: 'park green',
			color: 'green',
			leyenda: ['30. Parque Vírgen de Fátima', '31. Parque Felipe Sassone', '32. Parque Urubamba', '33. Parque de las Leyendas'],
		},
		{
			icono: 'health orange',
			color: 'orange',
			leyenda: ['34. Hospital Centro Médico Naval', '35. Hospital Essalud', '36. Hospital Santa Rosa', '37. Clínica Centenario Peruano Japonesa', '38. Clínica Stella Maris'],
		},
		{
			icono: 'icon_station',
			color: 'yellow2',
			leyenda: ['39. Estación La Alborada', '40. Estación Elio', '41. Estación San Marcos', '42. Estación Óscar R. Benavides'],
		},
	],
	gallerySites: [
		{
			title: 'Universidad San Marcos',
			img: require('../Assets/images/inicio/slider/sitios/miniatura/San-Marcos-1.png'),
			imgDesktop: require('../Assets/images/inicio/slider/sitios/desktop/San-Marcos-1.png'),
			imgMovil: require('../Assets/images/inicio/slider/sitios/movil/San-Marcos-1.png'),
		},
		{
			title: 'Universidad Católica',
			img: require('../Assets/images/inicio/slider/sitios/miniatura/catolica-1.png'),
			imgDesktop: require('../Assets/images/inicio/slider/sitios/desktop/catolica-1.png'),
			imgMovil: require('../Assets/images/inicio/slider/sitios/movil/catolica-1.png'),
		},
		{
			title: 'Mass',
			img: require('../Assets/images/inicio/slider/sitios/desktop/mass.png'),
			imgDesktop: require('../Assets/images/inicio/slider/sitios/desktop/mass.png'),
			imgMovil: require('../Assets/images/inicio/slider/sitios/movil/mass.png'),
		},
		{
			title: 'Plaza San Miguel',
			img: require('../Assets/images/inicio/slider/sitios/miniatura/plaza-san-miguel.png'),
			imgDesktop: require('../Assets/images/inicio/slider/sitios/desktop/plaza-san-miguel.png'),
			imgMovil: require('../Assets/images/inicio/slider/sitios/movil/plaza-san-miguel.png'),
		},
		{
			title: 'Parque Felipe Sassone',
			img: require('../Assets/images/inicio/slider/sitios/miniatura/parque-felipe.jpg'),
			imgDesktop: require('../Assets/images/inicio/slider/sitios/desktop/parque-felipe.png'),
			imgMovil: require('../Assets/images/inicio/slider/sitios/movil/parque-felipe.jpg'),
		},
		{
			title: 'Parque Urubamba',
			img: require('../Assets/images/inicio/slider/sitios/miniatura/Urubamba.png'),
			imgDesktop: require('../Assets/images/inicio/slider/sitios/desktop/Urubamba.png'),
			imgMovil: require('../Assets/images/inicio/slider/sitios/movil/Urubamba.png'),
		},
	],
	metro: [
		{
			title: 'DE ESTE A OESTE',
			title2: 'EN 45 MIN.',
			img: require('../Assets/images/inicio/iconos/iconos-info/modernidad.png'),
		},
		{
			title: 'AUMENTO DE VALOR',
			title: 'DE INMUEBLE',
			img: require('../Assets/images/inicio/iconos/iconos-info/valoracion.png'),
		},
		{
			title: 'MODERNINAD',
			title2: 'Y TECNOLOGÍA',
			img: require('../Assets/images/inicio/iconos/iconos-info/distance.png'),
		},

		{
			title: 'SEGURIDAD',
			title2: '',
			img: require('../Assets/images/inicio/iconos/iconos-info/seguridad.png'),
		},
	],
};

export default inicio;
