import { useEffect, useRef, useState } from 'react';
import './floatform.scss';
import MediaQuery from 'react-responsive';
import { InputChecked } from './inputChecked';
import { FormContainer } from '../../../components/forms/Form';
import { BaseValidatonSchema } from '../../../components/common/forms/constraints/ValidatonSchemas';
import { InputText } from './input';
import axios from 'axios';
import Swal from 'sweetalert2';
import { saveLead } from '../../../handleSubmit/handleSubmitSantaBeatriz';

export const FloatForm = () => {
	const ContentImg = useRef(null);
	const [openForm, setOpenForm] = useState(true);
	const initialValues = {
		fname: '',
		lname: '',
		telephone: '',
		email: '',
	};

	const onSubmit = async (values, formik) => {
		try {
			await saveLead(values, formik);

			let modal = document.querySelector('.ga-modal');
			modal.className = 'ga-modal active';
			formik.setSubmitting(false);
			formik.resetForm();

			// window.dataLayer &&
			// 	window.dataLayer.push({
			// 		event: 'gaEvent',
			// 		eventCategory: 'Whatsapp - Formulario',
			// 		eventAction: 'Envio de formulario',
			// 		eventValue: 5,
			// 	});
		} catch (error) {
			formik.setSubmitting(false);
			Swal.fire({
				title: '',
				text: 'Error, inténtalo de nuevo',
				type: 'error',
				confirmButtonText: 'Aceptar',
			});
		}
	};
	const comprobarPosicion = () => {
		const fixedElement = document.querySelector('.float-form');
		const footer = document.querySelector('.Contacto');
		if (fixedElement && footer) {
			const fixedElementRect = fixedElement.getBoundingClientRect();
			const footerRect = footer.getBoundingClientRect();
			if (fixedElementRect.bottom >= footerRect.top) {
				fixedElement.classList.add('hidden');
			} else {
				fixedElement.classList.remove('hidden');
			}
		}
	};
	const toggleActiveForm = value => {
		console.log(value);
		setOpenForm(value);
	};
	useEffect(() => {
		window.addEventListener('scroll', comprobarPosicion);
	}, []);
	return (
		<div className={`float-form ${openForm == true ? 'closed' : 'opened'}`} ref={ContentImg}>
			<FormContainer initialValues={initialValues} validationSchema={BaseValidatonSchema} onSubmit={onSubmit}>
				{form => {
					const { handleSubmit, isSubmitting } = form;
					console.log(form.errors);
					return (
						<form onSubmit={handleSubmit}>
							<div className='mask icon-close d-close' onClick={() => toggleActiveForm(!openForm)}></div>
							<div
								className='content-inputs-form flex flex-row'
								onClick={() => {
									toggleActiveForm(false);
								}}
							>
								<div className='flex content-dinamic'>
									<span className='text-primary title-down'>¡Cotiza aquí!</span>
								</div>
								<div className='flex flex-wrap '>
									<div className='order'>
										<InputText name='fname' placeholder='Nombre*' form={form}></InputText>
									</div>
								</div>
								<div className='flex flex-wrap '>
									<div className='order'>
										<InputText name='lname' placeholder='Apellido*' form={form}></InputText>
									</div>
								</div>
								<div className='flex flex-wrap '>
									<div className='order'>
										<InputText type='tel' name='telephone' placeholder='Celular*' form={form}></InputText>
									</div>
								</div>
								<div className='flex flex-wrap '>
									<div className='order'>
										<InputText name='email' placeholder='Correo*' form={form}></InputText>
									</div>
								</div>
								<div className='content-politics'>
									<div className='mr-3'>
										<InputChecked form={form} name='terms' color='#f2a331'></InputChecked>
									</div>
									<p className='md:text-1xl sm:text-0/9xl mobile:text-0/7xl  text-gray-300'>
										Acepto todos los
										<br />
										<a href={'/politica-de-privacidad.pdf'} className='md:text-1xl sm:text-0/9xl mobile:text-0/7xl text-underline' target='_blank'>
											términos y condiciones.
										</a>
									</p>
								</div>
							</div>
							<button type='submit cursor-pointer' className='submit' style={{ cursor: 'pointer' }}>
								<span className='text-white'>Enviar</span>
							</button>
						</form>
					);
				}}
			</FormContainer>
		</div>
	);
};
