window.$ = window.jQuery;
window.jQuery = window.$;

export const settings2 = {
	dots: true,
	centerMode: true,
	speed: 200,
	variableWidth: true,
	infinite: true,
	slidesToShow: 3,
	slidesToScroll: 1,
	beforeChange: function (current, next) {},
};

export const optionTypology = {
	rewind: true,
	loop: false,
	nav: true,
	center: false,
	autoWidth: true,
	speed: 1000,
	smartSpeed: 300,
	dots: true,
	margin: 10,
};

export const options2 = {
	items: 2,
	rewind: false,
	loop: true,
	nav: true,
	center: true,
	speed: 1000,
	smartSpeed: 300,
	dots: true,
	responsive: {
		0: {
			items: 2,
			center: true,
			autoWidth: true,
		},
		500: {
			margin: 0,
			items: 2,
			autoWidth: false,
		},
	},
};
export const optionsCercado = {
	items: 2,
	rewind: false,
	loop: true,
	nav: true,
	center: true,
	speed: 1000,
	smartSpeed: 300,
	dots: true,
	responsive: {
		0: {
			items: 2,
			center: true,
			autoWidth: false,
		},
		700: {
			margin: 0,
			items: 2,
			autoWidth: false,
		},
	},
};
export const options = {
	items: 2,
	rewind: false,
	loop: true,
	nav: true,
	center: true,
	speed: 1000,
	smartSpeed: 300,
	dots: true,
	responsive: {
		0: {
			items: 2,
			center: true,
			autoWidth: true,
		},
		700: {
			margin: 0,
			items: 2,
			autoWidth: false,
		},
	},
};
export const events = {
	onChanged: function (e) {},
};
export const configModal = {
	selector: '.content-slide-interiores',
	thumbnail: true,
	animateThumb: false,
	showThumbByDefault: false,
	subHtmlSelectorRelative: true,
	rotate: false,
	actualSize: false,
	download: true,
	pinterest: false,
	googlePlus: false,
	twitter: false,
};
export const configTipologia = {
	selector: '.img-slider',
	thumbnail: true,
	animateThumb: false,
	showThumbByDefault: false,
	subHtmlSelectorRelative: true,
	rotate: false,
	actualSize: false,
	download: true,
	pinterest: false,
	googlePlus: false,
	twitter: false,
	controls: false,
};
export const configMap = {
	selector: '.content-map',
	thumbnail: false,
	animateThumb: false,
	showThumbByDefault: false,
	subHtmlSelectorRelative: true,
	rotate: false,
	actualSize: false,
	download: true,
	pinterest: false,
	googlePlus: false,
	twitter: false,
};

export const initialValues = {
	fname: '',
	lname: '',
	telephone: '',
	email: '',
	terms: false,
};

export const maps2 = {
	dots: true,
	centerMode: true,
	speed: 100,
	infinite: true,
	arrows: true,
	variableWidth: true,
	responsive: [
		{
			breakpoint: 740,
			settings: {
				centerMode: true,
				slidesToShow: 2,
				slidesToScroll: 1,
				infinite: true,
				dots: true,
			},
		},
		{
			breakpoint: 3000,
			settings: {
				variableWidth: false,
				centerMode: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
				dots: false,
			},
		},
	],
	beforeChange: function (current, next) {
		window.$(`.imagen-map`).removeClass('active');
		window.$(`.img-${next}`).addClass('active');
		window.$(`.button`).removeClass('active');
		window.$(`.button-${next}`).addClass('active');
	},
};
export const maps = {
	dots: true,
	centerMode: true,
	speed: 100,
	infinite: true,
	arrows: true,
	variableWidth: true,
	responsive: [
		{
			breakpoint: 740,
			settings: {
				centerMode: true,
				slidesToShow: 2,
				slidesToScroll: 1,
				infinite: true,
				dots: true,
			},
		},
		{
			breakpoint: 3000,
			settings: {
				variableWidth: false,
				centerMode: false,
				slidesToShow: 1,
				slidesToScroll: 1,
				infinite: true,
				dots: false,
			},
		},
	],
	beforeChange: function (current, next) {
		window.$(`.imagen-map`).removeClass('active');
		window.$(`.img-${next}`).addClass('active');
		window.$(`.button`).removeClass('active');
		window.$(`.button-${next}`).addClass('active');
	},
};
