export const data = {
	sites: [
		{
			icono: 'study',
			color: 'blue',
			name: 'EDUCACIÓN',
			leyenda: [
				'1. Universidad Tecnológica del Perú',
				'2. Universidad Inca Garcilaso de la Vega',
				'3. Universidad del Pacífico',
				'4. UPC',
				'5. Instituto Arzobispo Loayza',
				'6. Colegio Trilce',
				'7. Colegio Dante Alighieri ',
				'8. Colegio Los Robles',
				'9. Colegio San Andrés',
				'10. Lima High School',
				'11. Zegel IPAE',
				'12. Idat',
			],
		},
		{
			icono: 'icon-bag',
			name: 'C. COMERCIALES',
			color: 'b-secondary',
			leyenda: ['13. La Rambla Brasil', '14. Polvos Azules', '15. C.C Risso', '16. C.C Arenales', '17. Ripley', '18. Saga Falabella'],
		},
		{
			icono: 'icon_money',
			color: 'b-primary',
			name: 'BANCOS',
			leyenda: ['19. Banco de la Nación', '20. Scotiabank', '21. BCP', '22. BBVA'],
		},
		{
			icono: 'buy',
			color: 'sky',
			name: 'SUPERMERCADOS',
			leyenda: ['23. Metro México', '24. Mercado Lobatón', '25. Metro Arenales', '26. Tienda Mass', '27. Plaza Vea Risso', '28. Tottus', '29. Plaza Vea'],
		},
		{
			icono: 'park green',
			color: 'green',
			name: 'PARQUES',
			leyenda: ['30. Parque de la Exposición', '31. Campo de Marte', '32. Parque Castilla', '33. Parque Habich', '34. Parque Cervantes'],
		},
		{
			icono: 'health orange',
			color: 'orange',
			name: 'SALUD',
			leyenda: ['35. Hospital Rebagliati', '36. Clínica La Luz', '37. Clínica Santa Beatriz', '38. Policlínico ESSALUD'],
		},

		{
			icono: 'icon-metro',
			color: 'yellow2',
			name: 'METROPOLITANO',
			leyenda: ['39. Estación México', '40. Estación Canadá', '41. Estación Estadio Nacional'],
		},
		{
			icono: 'globes ',
			color: 'bg-purple',
			name: 'ENTRETENIMIENTO',
			leyenda: ['42. Circuito Mágico del Agua', '43. Museo de Historia Natural', '44. Estadio Nacional', '45. MALI', '46. Castillo Rospigliosi', '47. Teatro Pirandello'],
		},
	],
	maps: [
		{ name: '0 active', img: require('../Assets/images/inicio/mapa/mapa-santa-beatriz/educacion.png') },
		{ name: '1', img: require('../Assets/images/inicio/mapa/mapa-santa-beatriz/comerciales.png') },
		{ name: '2', img: require('../Assets/images/inicio/mapa/mapa-santa-beatriz/bancos.png') },
		{ name: '3', img: require('../Assets/images/inicio/mapa/mapa-santa-beatriz/supermercados.png') },
		{ name: '4', img: require('../Assets/images/inicio/mapa/mapa-santa-beatriz/parques.png') },
		{ name: '5', img: require('../Assets/images/inicio/mapa/mapa-santa-beatriz/salud.png') },
		{ name: '6', img: require('../Assets/images/inicio/mapa/mapa-santa-beatriz/metropolitano.png') },
		{ name: '7', img: require('../Assets/images/inicio/mapa/mapa-santa-beatriz/entretenimiento.png') },
	],
	gallery: [
		{
			title: 'Centro Financiero',
			img: require('../Assets/images/inicio/main/santabeatriz/sites/compress-financiera.webp'),
		},
		{
			title: 'Circuito Mágico de las Aguas',
			img: require('../Assets/images/inicio/main/santabeatriz/sites/compress-circuito-de-las-aguas.webp'),
		},
		{
			title: 'Hospital Rebagliati',
			img: require('../Assets/images/inicio/main/santabeatriz/sites/compress-hospital_rebagliati.webp'),
		},
		{
			title: 'Metropolitano',
			img: require('../Assets/images/inicio/main/santabeatriz/sites/compress-metropolitano.webp'),
		},
		{
			title: 'CC. Real Plaza Salaverry',
			img: require('../Assets/images/inicio/main/santabeatriz/sites/compress-real-plaza-salaverry.webp'),
		},
		{
			title: 'UTP',
			img: require('../Assets/images/inicio/main/santabeatriz/sites/compress-utp.webp'),
		},
	],
};
