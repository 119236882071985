import MediaQuery from 'react-responsive';
import { settings2 } from '../../config';
import Slider from 'react-slick';

export const SectionAttr = () => {
	const estiloDeVida = [
		{
			class: 'secondary',
			subtitle: 'CERCA A',
			title: 'UNIVERSIDADES',
			paragraph: 'A 5 minutos de la UTP, U. Inca Garcilaso de la Vega, U. Pacífico, UPC y más.',
			img: require('../../../Assets/images/inicio/iconos/study.png'),
		},
		{
			class: 'c-yellow',
			subtitle: 'FRENTE AL',
			title: 'METROPOLITANO',
			paragraph: 'Estarás a 4 mins. de la Estación México.',
			img: require('../../../Assets/images/inicio/iconos/metropolitano-icon.png'),
		},
		{
			class: 'c-green',
			subtitle: 'CERCANÍA A',
			title: 'PARQUES',
			paragraph: 'Como el Parque de la Exposición, Parque Castilla y el Campo de Marte.',
			img: require('../../../Assets/images/inicio/iconos/parques.png'),
		},
		{
			class: 'c-primary',
			subtitle: 'CERCA A <span class="bold c-primary">CENTROS</span>',
			title: 'COMERCIALES',
			paragraph: 'Como Risso, Arenales, Polvos Azules, La Rambla Brasil.',
			img: require('../../../Assets/images/inicio/iconos/comercial-icon.png'),
		},
	];
	return (
		<section className='Quality' id='seccion-estilo-de-vida'>
			<div className='content-title flex flex-col'>
				<label className='leading-none flex text-center mb-1'>
					<span className='title title-1 text-center d-block white leading-none'>INVIERTE EN UN</span>
					<span className='title title-2 bold text-center d-block white leading-none'> PROYECTO</span>
				</label>
				<span className='title title-2 bold text-center d-block white leading-none'>
					DE
					<img className='img' src={require('../../../Assets/images/inicio/main/santabeatriz/backgrounds/alto-nivel.png')}></img>
				</span>
				<img className='tag' src={require('../../../Assets/images/inicio/main/santabeatriz/icons/tag.png')}></img>
			</div>
			<p className='paragraph white'>
				Helio se encuentra ubicado estratégicamente a pocos pasos de todo lo que necesitas para seguir creciendo. ¡Alza vuelo con todo lo que hemos pensado para ti!
			</p>
			<MediaQuery query='(max-width: 740px)'>
				<Slider {...settings2}>
					{estiloDeVida.map((item, index) => {
						return (
							<div key={index} className='content-slide-quality'>
								<div className='slide-quality'>
									<div className='content-img'>
										<img src={item.img}></img>
									</div>
									<div className='info-quality'>
										<span className={'title-1 ' + item.class} dangerouslySetInnerHTML={{ __html: item.subtitle }}></span>
										<span className={'title-2 bold ' + item.class}>{item.title}</span>
										<p className='paragraph'>{item.paragraph}</p>
									</div>
								</div>
							</div>
						);
					})}
				</Slider>
			</MediaQuery>
			<MediaQuery query='(min-width: 740px)'>
				<div className='content-quality d-flex'>
					{estiloDeVida.map((item, index) => {
						return (
							<div key={index} className='content-slide-quality  idx_content-slide-quality'>
								<div className='slide-quality'>
									<div className='content-img'>
										<img src={item.img}></img>
									</div>
									<div className='info-quality'>
										<span className={'title-1 ' + item.class} dangerouslySetInnerHTML={{ __html: item.subtitle }}></span>
										<span className={'title-2 bold ' + item.class}>{item.title}</span>
										{/* <p className="paragraph">
                                    {item.paragraph}
                                </p> */}
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</MediaQuery>
		</section>
	);
};
