const about = {
    proyectos:[
        {
            title:"Loma Amarilla",
            subtitle:"132 departamentos",
            distrito:"Surco",
            img:require("../Assets/images/AboutUs/Loma-Amarilla.jpg"),
        },
        {
            title:"Córdova 249",
            subtitle:"24 departamentos",
            distrito:"Miraflores",
            img:require("../Assets/images/AboutUs/Cordova.jpg"),
        },
        {
            title:"Lumière",
            subtitle:"24 oficinas",
            distrito:"Surco",
            img:require("../Assets/images/AboutUs/Lumiere.jpg"),
        },
        {
            title:"Lomas de Carabayllo",
            subtitle:"720 departamentos",
            distrito:"Carabayllo",
            img:require("../Assets/images/AboutUs/Lomas-de-Carabayllo.jpg"),
        },
    ]
}
export default about