import React, { Component } from 'react';
import './inicio.scss';
import Slider from 'react-slick';
import YouTube from 'react-youtube';
import $ from 'jquery';
import MediaQuery from 'react-responsive';
import 'font-awesome/css/font-awesome.min.css';
import { configSliderMain, configSliderMain2 } from './config';
var loop;
export const SliderInicio = ({ images }) => {
	return (
		<div className='Inicio'>
			<div className='Slider-diap'>
				<div className='splide background-slider'>
					<Slider className='Slider-main-slick' {...configSliderMain2}>
						{images.map((item, index) => {
							return (
								<picture key={index} className={'diap ' + 'slider-' + index}>
									<source srcSet={item.img} type='image/webp' media='(max-width: 740px)' />
									<img src={item.desktop} alt='Image 1' />
								</picture>
							);
						})}
					</Slider>
				</div>
			</div>
		</div>
	);
};
