import React, { Component } from 'react';
import { SliderInicio } from '../../../components/SliderInicio/inicio';
import Modal from '../../../components/modal/modal.js';
import MediaQuery from 'react-responsive';
import Slider from 'react-slick';
import sr from '../../../components/scrollreveal/scroll.js';
import 'lightgallery/dist/css/lightgallery.css';
import 'lightgallery/dist/js/lightgallery';
import 'lightgallery/dist/js/lightgallery-all';
import { updateInf } from '../../../data/components/inicio.js';
import { updateInfMain } from '../../../data/components/slider.js';
import './template.scss';
import Swal from 'sweetalert2';
import { FormContainer, checkableBoolProps, setInputProps } from '../../../components/common/forms/Form.js';
import { BaseValidatonSchema } from '../../../components/common/forms/constraints/ValidatonSchemas.js';
import OwlCarousel from 'react-owl-carousel2';
import axios from 'axios';
import PonlePlay from '../../sections/Ponle-play/video.js';
import { connect } from 'react-redux';
import { configMap, configModal, configTipologia, events, initialValues, maps, options, optionsCercado, settings2 } from '../../config.js';
import $ from 'jquery';
import { submitFormCercado } from '../../../handleSubmit/handleSubmitCercado.js';
import Whatsapp from '../../../components/whatsapp/whatsapp.js';
import { saveLead } from '../../../handleSubmit/handleSubmitSantaBeatriz.js';

class Inicio extends Component {
	constructor(props) {
		super(props);
		this.properties = this.props.properties;
		// this.mailService = new MailService()
		this.submitForm = this.submitForm.bind(this);
		this.onDragged = this.onDragged.bind(this);
		this.state = {
			areaActive: 0,
			areaComun: 0,
			interior: 0,
			recorrido: '',
			events: {
				onDragged: function (item) {
					// console.log(item)
				},
				onChanged: this.onDragged,
			},
			eventsInterior: { onDragged: this.onDraggedInteriores, onChanged: function (item) {} },
			options: {
				rewind: true,
				loop: false,
				nav: true,
				center: false,
				autoWidth: true,
				speed: 1000,
				smartSpeed: 300,
				dots: true,
				margin: 10,
			},
		};
	}
	onDragged() {}
	componentDidMount() {
		$('.MyCarouselHorizontal').lightGallery(configModal);
		$('.content-img-typology').lightGallery(configTipologia);
		$('#section-ubicacion').lightGallery(configMap);

		document.querySelector('.content-map-linea').onscroll = function () {
			let element = document.querySelector('.content-map-linea');
			let scrollLeft = element.scrollLeft;
			let scrollWidth = element.scrollWidth - 60;
			let moveScroll = document.querySelector('.moveScroll');
			console.log((scrollLeft / scrollWidth) * 100);
			moveScroll.style.left = (scrollLeft / scrollWidth) * 100 + '%';
		};
		function right(el) {
			el.classList.add('animation-right');
		}
		function left(el) {
			el.classList.add('animation-left');
		}
		sr.reveal('.globo-1', { opacity: 1, beforeReveal: left });
		sr.reveal('.globo-2', { opacity: 1, beforeReveal: right });
		sr.reveal('.globo-3', { opacity: 1, beforeReveal: right });
		sr.reveal('.globo-4', { opacity: 1, beforeReveal: left });
		sr.reveal('.globo-6', { opacity: 1, beforeReveal: left });
		sr.reveal('.globo-5', { opacity: 1, beforeReveal: right });
		sr.reveal('.globo-7', { opacity: 1, beforeReveal: left });
		sr.reveal('.globo-8', { opacity: 1, beforeReveal: right });
		sr.reveal('.globo-9', { opacity: 1, beforeReveal: right });
		sr.reveal('.globo-10', { opacity: 1, beforeReveal: left });
		sr.reveal('.globo-11', { opacity: 1, beforeReveal: left });

		var slideElement = document.querySelectorAll('.react_lightgallery_item');
		slideElement.forEach(e => {
			e.addEventListener('mouseup', function (e) {
				console.log(e);
				// console.log("pruena mous")
			});
		});
	}
	sliderGoTo(num) {
		this.sliderMaps.slickGoTo(num);
	}
	changeTypology(num, e) {
		let Typology = this.props.data.dataTipologia.filter(e => {
			if (e.position == num) {
				return e;
			}
		});
		this.props.updateInf({ num, Typology });
	}
	moveTo(section, distance) {
		window.scrollTo(0, document.querySelector(section).offsetTop - distance);
	}

	formProps = {
		initialValues: initialValues,
		validationSchema: BaseValidatonSchema,
		onSubmit: this.submitForm,
	};
	async submitForm(values, formik) {
		const form = document.querySelector('form.main-bottom');
		try {
			await saveLead(values, formik);

			formik.setSubmitting(false);
			formik.resetForm();
			form.reset();
			let modal = document.querySelector('.ga-modal');
			modal.className = 'ga-modal active';
			window.dataLayer &&
				window.dataLayer.push({
					event: 'gaEvent',
					eventCategory: 'Whatsapp - Formulario',
					eventAction: 'Envio de formulario',
					eventValue: 5,
				});
		} catch (error) {
			formik.setSubmitting(false);
			Swal.fire({
				title: '',
				text: 'Error, inténtalo de nuevo',
				type: 'error',
				confirmButtonText: 'Aceptar',
			});
		}
	}
	closeDialog(element) {
		try {
			$('.ga-modal').removeClass('active');
		} catch (error) {}
	}
	render() {
		let arraycommon = this.props.properties.comunes;
		let arrayInteriores = this.props.properties.interiores;
		const eventsCommon = {
			onChanged: function (e) {
				if (e.page.index >= 0) {
					console.log(e.page.index);
					$('#title-common').text(arraycommon[e.page.index].title);
					$('.title-slider .icon').attr('id', arraycommon[e.page.index].icon);
				}
			},
		};
		const eventsInteriores = {
			onChanged: function (e) {
				if (e.page.index >= 0) {
					console.log(e.page.index);
					$('#title-interiores').text(arrayInteriores[e.page.index].title);
				}
			},
		};

		return (
			<main className='Page inicio'>
				<SliderInicio images={this.props.properties.sliderMain} />
				<PonlePlay></PonlePlay>
				<section className='description' id='page-inicio'>
					<img className='globo-1 globe-left' src={require('../../../Assets/images/landing/globo-5.png')}></img>
					<img className='globo-2 globe-right' src={require('../../../Assets/images/landing/globo-8.png')}></img>
					<img className='globo-3 globe-right' src={require('../../../Assets/images/landing/globo-9.png')}></img>
					<img className='globo-4 globe-left' src={require('../../../Assets/images/landing/globo-12.png')}></img>
					<picture>
						<source className='background ' media='(min-width: 740px)' srcset={require('../../../Assets/images/inicio/render/render-desktop.png')}></source>
						<img className='background ' src={require('../../../Assets/images/inicio/render/render.png')}></img>
					</picture>
					<h1 className='content-title'>
						<span className='title title-1 text-center d-block'>
							<img className='underline' src={require('../../../Assets/images/inicio/underline.png')}></img>
							MANTEN TU
						</span>
						<span className='title title-2 bold text-center d-block'>FORMA DE VIVIR</span>
						<img className='logo-bono desktop' src={require('../../../Assets/images/inicio/iconos/bono-verde.png')}></img>
					</h1>
					<div className='content-description'>
						<span className='white title-des-1 text-center d-block'>
							2 <span className='ball'></span> 3
						</span>
						<span className='white text-center d-block title-dorm title-des-2'>dormitorios</span>
						<span className='white  title-des-3 text-center d-block'>
							desde <span className='white bold'>53 m² </span>hasta <span className='white bold'>69 m²</span>
						</span>
						<span className='white  title-des-4 text-center d-block'>
							en <span className='secondary bold title-des-3 '>Av. Venezuela 3258, </span>
							<span className='title-des-5 white text-center d-block'>Cercado de Lima</span>
						</span>
						<span className='title-des-4 white text-center d-block'>Frente a</span>
						<img className='san-miguel' src={require('../../../Assets/images/inicio/underline/san-miguel.png')}></img>
						<img className='logo-bono movil' src={require('../../../Assets/images/inicio/iconos/bono-verde.png')}></img>
					</div>
				</section>

				<section className='Quality' id='seccion-estilo-de-vida'>
					<div className='content-title'>
						<span className='title title-1 text-center d-block white'>
							<img className='underline' src={require('../../../Assets/images/inicio/underline/supera.png')}></img>
							SIGUE TU
						</span>
						<span className='title title-2 bold text-center d-block white'>ESTILO DE VIDA</span>
					</div>
					<p className='paragraph white'>
						Helio se encuentra ubicado estratégicamente a pocos pasos de todo lo que necesitas para seguir creciendo. ¡Alza vuelo con todo lo que hemos pensado para ti!
					</p>
					<MediaQuery query='(max-width: 740px)'>
						<Slider {...settings2} ref={c => (this.slider = c)}>
							{this.properties.estiloDeVida.map((item, index) => {
								return (
									<div key={index} className='content-slide-quality'>
										<div className='slide-quality'>
											<div className='content-img'>
												<img src={item.img}></img>
											</div>
											<div className='info-quality'>
												<span className={'title-1 ' + item.class}>{item.subtitle}</span>
												<span className={'title-2 bold ' + item.class}>{item.title}</span>
												<p className='paragraph'>{item.paragraph}</p>
											</div>
										</div>
									</div>
								);
							})}
						</Slider>
					</MediaQuery>
					<MediaQuery query='(min-width: 740px)'>
						<div className='content-quality d-flex'>
							{this.properties.estiloDeVida.map((item, index) => {
								return (
									<div key={index} className='content-slide-quality'>
										<div className='slide-quality'>
											<div className='content-img'>
												<img src={item.img}></img>
											</div>
											<div className='info-quality'>
												<span className={'title-1 ' + item.class}>{item.subtitle}</span>
												<span className={'title-2 bold ' + item.class}>{item.title}</span>
												{/* <p className="paragraph">
                                                    {item.paragraph}
                                                </p> */}
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</MediaQuery>
				</section>
				<section className='Gallery areas-comunes' id='section-areas-comunes'>
					<img className='globo-5 globe-right' src={require('../../../Assets/images/landing/globo-11.png')}></img>
					<img className='globo-6 globe-left' src={require('../../../Assets/images/landing/globo-6.png')}></img>

					<div className='content-title'>
						<img className='background-white movil' src={require('../../../Assets/images/inicio/iconos/background-interiores.png')}></img>
						<img className='background-white desktop' src={require('../../../Assets/images/inicio/iconos/background-top-desktop.png')}></img>
						<div className='movil background-font'></div>
						<span className='title title-1 text-center d-block '>ÁREAS PARA QUE </span>
						<img className='underline areas-comunes' src={require('../../../Assets/images/inicio/underline/nunca-dejes-de-celebrar.png')}></img>
					</div>
					<p className='paragraph'>
						<p className='paragraph-desktop'>
							¡La diversión comienza en nuestras exclusivas áreas comunes! En Helio contarás con espacios para celebrar los momentos más importantes de tu vida junto a los que más
							quieres. Estas áreas están diseñadas para elevar la vida de cada familia con diferentes actividades sin salir de casa.
						</p>
					</p>
					<div className='b-white content-owlcarousel'>
						<OwlCarousel ref='car' options={optionsCercado} events={eventsCommon} className='owl-carousel MyCarouselHorizontal owl-theme owl-loaded owl-drag' id='carousel-comunes'>
							{this.properties.comunes.map((item, index) => {
								return (
									<div
										className='content-slide-interiores'
										data-src={item.img}
										href={item.imgDesktop}
										data-exthumbimage={item.img}
										data-sub-html={'#interior-' + index}
										facebookShareUrl=''
									>
										<div className='slide-interiores'>
											<div className='content-img'>
												<picture className='expand-img'>
													<source media='(min-width: 740px)' srcset={item.img}></source>
													<img src={item.imgmovil}></img>
												</picture>
											</div>
											{/* <div className="title-slide">
                                            {item.title}
                                        </div> */}
										</div>
										<div className='hidden' id={'interior' + index}>
											<h4>{item.title}</h4>
										</div>
									</div>
								);
							})}
						</OwlCarousel>
					</div>
					<div className='w-100'></div>
					<div className='background-font size-2'></div>
					<div className='title-slider'>
						<div className='icon' id='lobby'></div>
						<span className='title' id='title-common'>
							{this.state.titlecomun}
						</span>
					</div>
				</section>
				<section className='Gallery interiores' id='section-interiores'>
					<img className='globo-5 globe-right' src={require('../../../Assets/images/landing/globo-11.png')}></img>
					<img className='globo-6 globe-left' src={require('../../../Assets/images/landing/globo-6.png')}></img>

					<div className='content-title'>
						<span className='title title-1 text-center d-block '>
							<img className='underline' src={require('../../../Assets/images/inicio/underline/los-mejores.png')}></img>
							BUENOS ACABADOS
						</span>
						<span className='title title-2 bold text-center d-block '>PARA TU HOGAR</span>
					</div>
					<p className='paragraph'>
						<p className='paragraph-desktop'>
							Los ambientes de los departamentos están pensados según la tendencia de los nuevos estilos de vida, con espacios funcionales e innovadores. Contarán con amplias estaciones
							de trabajo y balcones que te harán sentir libre desde tu hogar.
						</p>
					</p>
					<div className='b-white content-owlcarousel'>
						<OwlCarousel ref='car' options={optionsCercado} events={eventsInteriores} className='owl-carousel MyCarouselHorizontal owl-theme owl-loaded owl-drag' id='carousel-interiores'>
							{this.properties.interiores.map((item, index) => {
								return (
									<div
										className='content-slide-interiores'
										data-src={item.img}
										href={item.imgDesktop}
										data-exthumbimage={item.img}
										data-sub-html={'#interior-' + index}
										facebookShareUrl=''
									>
										<div className='slide-interiores'>
											<div className='content-img'>
												<picture className='expand-img'>
													<source media='(min-width: 740px)' srcset={item.img}></source>
													<img src={item.imgmovil}></img>
												</picture>
											</div>
											{/* <div className="title-slide">
                                            {item.title}
                                        </div> */}
										</div>
										<div className='hidden' id={'interior' + index}>
											<h4>{item.title}</h4>
										</div>
									</div>
								);
							})}
						</OwlCarousel>
					</div>
					<div className='title-slider'>
						{/* <div className="icon" id="lobby"></div> */}
						<span className='title' id='title-interiores'>
							Dormitorio Principal
						</span>
					</div>
				</section>
				<section className='Typology' id='section-tipologias'>
					<img className='globo-7 globe-left' src={require('../../../Assets/images/landing/globo-12.png')}></img>
					<img className='globo-8 globe-right' src={require('../../../Assets/images/landing/globo-13.png')}></img>

					<div className='content-title'>
						<span className='title title-1 text-center d-block'>DEPARTAMENTOS CON </span>
						<span className='title title-2 bold text-center d-block '>
							ESPACIOS BÁSICOS
							<img className='underline' src={require('../../../Assets/images/inicio/underline/unicos.png')}></img>
						</span>
					</div>
					<p className='paragraph '>¡Encuentra el departamento que se ajuste más a ti y a tus necesidades! El proyecto tiene 5 diferentes tipologías con depas de 2 y 3 dormitorios</p>
					<div className='content-tipology'>
						<div className='content-btn'>
							<div
								className={`btn-dorm btn-dorm-1 ${this.props.data.changeTypology.includes(2) ? 'active' : ''}`}
								onClick={e => {
									this.changeTypology(2, e);
								}}
							>
								<div className='content-hover'>
									<span className='num'>2</span>
									<span className='dorm'>Dormitorios</span>
								</div>
							</div>
							<div
								className={`btn-dorm ${this.props.data.changeTypology.includes(3) ? 'active' : ''}`}
								onClick={e => {
									this.changeTypology(3, e);
								}}
							>
								<div className='content-hover'>
									<span className='num'>3</span>
									<span className='dorm'>Dormitorios</span>
								</div>
							</div>
						</div>
						<SliderTypology />

						{/* component */}
						<InfoSliderTypo></InfoSliderTypo>
					</div>
				</section>
				<section className='Sites-around' id='section-ubicacion'>
					<img className='globo-9 globe-right' src={require('../../../Assets/images/landing/globo-8.png')}></img>
					<img className='globo-10 globe-left' src={require('../../../Assets/images/landing/globo-5.png')}></img>
					<div className='content-title'>
						<span className='title title-1 text-center d-block '>VIVE CERCA </span>
						<span className='title title-2 bold text-center d-block '>
							A TODO
							<img className='underline movil' src={require('../../../Assets/images/inicio/underline/lo-que-necesitas.png')}></img>
							<img className='underline desktop' src={require('../../../Assets/images/inicio/underline/lo-que-necesitas-desktop.png')}></img>
						</span>
					</div>
					<div className='content-sites-around'>
						<div className='content-map' href={require('../../../Assets/images/inicio/mapa/mapa/all-sites.jpg')}>
							{this.properties.maps.map((item, index) => {
								return <img key={index} className={'imagen-map img-' + item.name} src={item.img}></img>;
							})}
						</div>
						<div className='content-maps'>
							<div className='button-maps'>
								<div
									className='button button-0 blue active'
									onClick={() => {
										this.sliderGoTo(0);
									}}
								>
									<div className='icon study'></div>
								</div>
								<div
									className='button button-1 b-secondary'
									onClick={() => {
										this.sliderGoTo(1);
									}}
								>
									<div className='icon globes'></div>
								</div>
								<div
									className='button button-2 b-primary'
									onClick={() => {
										this.sliderGoTo(2);
									}}
								>
									<div className='icon icon_money'></div>
								</div>
								<div
									className='button button-3 sky'
									onClick={() => {
										this.sliderGoTo(3);
									}}
								>
									<div className='icon buy'></div>
								</div>
								<div
									className='button button-4 green'
									onClick={() => {
										this.sliderGoTo(4);
									}}
								>
									<div className='icon park'></div>
								</div>
								<div
									className='button button-5 orange'
									onClick={() => {
										this.sliderGoTo(5);
									}}
								>
									<div className='icon health orange'></div>
								</div>
								<div
									className='button button-6 yellow2'
									onClick={() => {
										this.sliderGoTo(6);
									}}
								>
									<div className='icon icon_station'></div>
								</div>
							</div>
							<Slider {...maps} ref={c => (this.sliderMaps = c)} className='slider-list-site'>
								{this.properties.sites.map((item, index) => {
									return (
										<div key={index} className='content-slide-quality'>
											<div className='slide-site'>
												<div className='content-img'>
													<div className={'content-icon ' + item.color}>
														<div className={'icon ' + item.icono}></div>
													</div>
												</div>
												<div className='info-quality'>
													{item.leyenda.map((paragraph, index) => {
														return (
															<p key={index} className='paragraph'>
																{paragraph}
															</p>
														);
													})}
												</div>
											</div>
										</div>
									);
								})}
							</Slider>
						</div>
					</div>
					<div className='b-white content-owlcarousel '>
						<OwlCarousel ref='car' options={optionsCercado} events={events} className='owl-carousel MyCarouselHorizontal owl-theme owl-loaded owl-drag' id='carousel-interiores'>
							{this.properties.gallerySites.map((item, index) => {
								return (
									<div className='content-slide-interiores ' data-src={item.img} href={item.imgDesktop} data-sub-html={'#interior-' + index}>
										<div className='slide-interiores'>
											<div className='content-img'>
												<picture className='expand-img'>
													<source media='(min-width: 740px)' srcset={item.img}></source>
													<img src={item.imgMovil}></img>
												</picture>
											</div>
											<div className='title-slide'>{item.title}</div>
										</div>
										<div className='hidden' id={'gallery-sites-' + index}>
											<h4>{item.title}</h4>
										</div>
									</div>
								);
							})}
						</OwlCarousel>
					</div>
				</section>
				<section className='Metro-de-lima' id='section-metro-de-lima'>
					<img className='globo-9 globe-right' src={require('../../../Assets/images/landing/globo-9.png')}></img>
					<img className='globo-11 globe-left' src={require('../../../Assets/images/landing/globo-5.png')}></img>
					<div className='background-site'>
						<img className='img-background' src={require('../../../Assets/images/inicio/iconos/background-yellow.png')}></img>
						<div className='content-title'>
							<span className='title title-1 text-center d-block white'>
								<img className='underline-2 movil' src={require('../../../Assets/images/inicio/underline/vuela.png')}></img>
								<img className='underline-2 desktop' src={require('../../../Assets/images/inicio/underline/vuela-desktop.png')}></img>
								MUEVETE POR
							</span>
							<span className='title title-2 bold text-center d-block white'>LIMA EN METRO</span>
						</div>
						<p className='white paragraph'>
							¡La modernidad llegará pronto a Helio! Con la nueva Línea 2 del metro de Lima podrás acortar los tiempos de transporte dentro de Lima gracias a sus 27 estaciones que
							conectan la zona este y oeste de Lima.
							<br></br>
							<span className='white nota-legal'>*Sujeto a tiempos de entrega de la Municipalidad Metropolitana de Lima</span>
							<img className='img-train movil' src={require('../../../Assets/images/inicio/mapa/train.png')}></img>
						</p>
						<img className='img-background-site movil' src={require('../../../Assets/images/inicio/iconos/background-place-movil.png')}></img>
						<img className='img-background-site desktop' src={require('../../../Assets/images/inicio/iconos/background-place-desktop.png')}></img>
					</div>
					<div className='content-slider-characteristic'>
						<MediaQuery query='(max-width: 740px)'>
							<Slider {...settings2} ref={c => (this.slider = c)} className='Slider-characteristic'>
								<div className='content-slide-quality'>
									<div className='slide-quality'>
										<div className='content-img'>
											<img src={require('../../../Assets/images/inicio/iconos/iconos-info/distance.png')}></img>
										</div>
										<div className='info-quality'>
											<span className='title-1 secondary'>DE ESTE A OESTE</span>
											<span className='title-2 bold secondary'>EN 45 MIN</span>
										</div>
									</div>
								</div>
								<div className='content-slide-quality'>
									<div className='slide-quality'>
										<div className='content-img'>
											<img src={require('../../../Assets/images/inicio/iconos/iconos-info/valoracion.png')}></img>
										</div>
										<div className='info-quality'>
											<span className='title-1 c-green'>AUMENTO DEL VALOR</span>
											<span className='title-2 bold c-green'>DE INMUEBLE</span>
										</div>
									</div>
								</div>
								<div className='content-slide-quality'>
									<div className='slide-quality'>
										<div className='content-img'>
											<img src={require('../../../Assets/images/inicio/iconos/iconos-info/modernidad.png')}></img>
										</div>
										<div className='info-quality'>
											<span className='title-1 c-primary'>MODERNIDAD</span>
											<span className='title-2 bold c-primary'>Y TECNOLOGÍA</span>
										</div>
									</div>
								</div>

								<div className='content-slide-quality'>
									<div className='slide-quality'>
										<div className='content-img'>
											<img src={require('../../../Assets/images/inicio/iconos/iconos-info/seguridad.png')}></img>
										</div>
										<div className='info-quality'>
											<span className='title-2 bold c-sky'>SEGURIDAD</span>
										</div>
									</div>
								</div>
							</Slider>
						</MediaQuery>
						<MediaQuery query='(min-width: 740px)'>
							<div className='content-slide-quality'>
								<div className='slide-quality'>
									<div className='content-img'>
										<img src={require('../../../Assets/images/inicio/iconos/iconos-info/distance.png')}></img>
									</div>
									<div className='info-quality'>
										<span className='title-1 secondary'>DE ESTE A OESTE</span>
										<span className='title-2 bold secondary'>EN 45 MIN</span>
									</div>
								</div>
							</div>
							<div className='content-slide-quality'>
								<div className='slide-quality'>
									<div className='content-img'>
										<img src={require('../../../Assets/images/inicio/iconos/iconos-info/valoracion.png')}></img>
									</div>
									<div className='info-quality'>
										<span className='title-1 c-green'>AUMENTO DEL VALOR</span>
										<span className='title-2 bold c-green'>DE INMUEBLE</span>
									</div>
								</div>
							</div>
							<div className='content-slide-quality'>
								<div className='slide-quality'>
									<div className='content-img'>
										<img src={require('../../../Assets/images/inicio/iconos/iconos-info/modernidad.png')}></img>
									</div>
									<div className='info-quality'>
										<span className='title-1 c-primary'>MODERNIDAD</span>
										<span className='title-2 bold c-primary'>Y TECNOLOGÍA</span>
									</div>
								</div>
							</div>

							<div className='content-slide-quality'>
								<div className='slide-quality'>
									<div className='content-img'>
										<img src={require('../../../Assets/images/inicio/iconos/iconos-info/seguridad.png')}></img>
									</div>
									<div className='info-quality'>
										<span className='title-2 bold c-sky'>SEGURIDAD</span>
									</div>
								</div>
							</div>
						</MediaQuery>
					</div>
					<div className='linea-2'>
						<div className='content-map-linea'>
							<img src={require('../../../Assets/images/inicio/mapa/mapa-metro.jpg')}></img>
						</div>
						<div className='scroll'>
							<div className='moveScroll'></div>
						</div>
					</div>
				</section>
				<section className='d-none' style={{ display: 'none' }}>
					<img className='' src={require('../../../Assets/images/inicio/tipologias/x-01.png')}></img>
					<img className='' src={require('../../../Assets/images/inicio/tipologias/x-02.png')}></img>
					<img className='' src={require('../../../Assets/images/inicio/tipologias/x-03.png')}></img>
					<img className='' src={require('../../../Assets/images/inicio/tipologias/x-04.png')}></img>
					<img className='' src={require('../../../Assets/images/inicio/tipologias/x-05.png')}></img>

					<img className='' src={require('../../../Assets/images/inicio/tipologias/sketch/plano_x01.png')}></img>
					<img className='' src={require('../../../Assets/images/inicio/tipologias/sketch/plano_x02.png')}></img>
					<img className='' src={require('../../../Assets/images/inicio/tipologias/sketch/plano_x03.png')}></img>
					<img className='' src={require('../../../Assets/images/inicio/tipologias/sketch/plano_x04.png')}></img>
					<img className='' src={require('../../../Assets/images/inicio/tipologias/sketch/plano_x05.png')}></img>
				</section>
				{/* <Whatsapp submitForm={submitFormCercado}></Whatsapp> */}
			</main>
		);
	}
}
class SliderTypo extends Component {
	constructor(props) {
		super(props);
		this.onDragged = this.onDragged.bind(this);
		this.state = {
			events: {
				onDragged: function (item) {},
				onChanged: this.onDragged,
			},
			eventsInterior: { onDragged: this.onDraggedInteriores, onChanged: function (item) {} },
			options: {
				rewind: true,
				loop: false,
				nav: true,
				center: false,
				autoWidth: true,
				speed: 1000,
				smartSpeed: 300,
				dots: true,
				margin: 10,
			},
		};
	}
	onDragged(event) {
		try {
			console.log(event.item.index);
			if (event.item.index >= 0 && event.item.index != null) {
				let data = JSON.parse(JSON.stringify(this.props.data.dataTipologia));
				let Typology = data.filter(e => {
					if (e.position == this.props.data.changeTypology[0]) {
						return e;
					}
				});
				let getTypologies = [Typology[0].tipologias[event.item.index]];
				Typology[0].tipologias = getTypologies;
				this.props.updateInfMain(Typology[0]);
			}
		} catch (err) {
			console.log(err);
		}
	}
	render() {
		return (
			<div className='content-img-typology '>
				{this.props.data.dataTipologia.map((e, index) => {
					return this.props.data.changeTypology.includes(e.position) ? (
						<div className={`content-slider ${this.props.data.changeTypology.includes(e.position) ? 'active' : ''}`} key={index + 'tipo'}>
							<OwlCarousel
								ref='car'
								options={this.state.options}
								events={this.state.events}
								className='owl-carousel MyCarouselHorizontal owl-theme owl-loaded owl-drag'
								id='carousel-interiores'
							>
								{e.tipologias.map(item => {
									return (
										<div className='img-slider' href={item.img}>
											<img className='tipo active' src={item.img}></img>
										</div>
									);
								})}
							</OwlCarousel>
						</div>
					) : null;
				})}
				<p className='paragraph'>*Planos, áreas y medidas referenciales. No incluye muebles decorativos.</p>
			</div>
		);
	}
}

const MapStatePropsSlider = state => {
	return {
		data: state.inicio,
	};
};
const SliderTypology = connect(MapStatePropsSlider, { updateInf, updateInfMain })(SliderTypo);

class InfoTypo extends Component {
	moveTo(section, distance) {
		window.scrollTo(0, document.querySelector(section).offsetTop - distance);
	}
	render() {
		return (
			<div className='content-characteristic'>
				<div className='title-characteristic'>
					<span className='type secondary bold d-block'>{this.props.data.infTypology.tipologias[0].name}</span>
					<span className='dorms d-block'>{this.props.data.infTypology.tipologias[0].dorms}</span>
					<span className='meters d-block'>{this.props.data.infTypology.tipologias[0].meters}</span>
				</div>
				<div className='characteristic'>
					{this.props.data.infTypology.features.map((item, index) => {
						return (
							<span key={index} className='data'>
								{item}
							</span>
						);
					})}
				</div>
				<div className='sketch-typology'>
					<img className='img-fluid' src={this.props.data.infTypology.tipologias[0].sketch.default}></img>
				</div>
				<div className='shadow-bottom'></div>
				<div
					className='btn-quote'
					onClick={() => {
						this.moveTo('#section-contacto', 70);
					}}
				>
					¡Cotiza aquí!
				</div>
			</div>
		);
	}
}

const MapStatePropsTypo = state => {
	return {
		data: state.sliderStore,
	};
};
const InfoSliderTypo = connect(MapStatePropsTypo, { updateInf, updateInfMain })(InfoTypo);

const MapStateProps = state => {
	return {
		data: state.inicio,
	};
};
export default connect(MapStateProps, { updateInf, updateInfMain })(Inicio);
