export const configSliderMain = {
	dots: true,
	fade: true,
	autoplay: true,
	autoplaySpeed: 7000,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	className: 'slider-inicio-item',
	beforeChange: function (currentSlide, nextSlide) {},
	pauseOnHover: false,
};
export const configSliderMain2 = {
	dots: true,
	fade: true,
	autoplay: false,
	autoplaySpeed: 7000,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	className: 'slider-inicio-item',
	beforeChange: function (currentSlide, nextSlide) {},
	pauseOnHover: false,
};
