import axios from 'axios';
import { FormContainer, setInputProps } from '../../components/common/forms/Form';
import { checkableBoolProps } from '../../components/forms/Form';
import './main.scss';
import '../cercado-de-lima/inicio/template.scss';
import { SliderInicio } from '../../components/SliderInicio/inicio';
import { slider } from './data';
import { SectionRecorrido } from './sectionRecorrido/sectionRecorrido';
import { SectionRender } from './sectionRender/sectionRender';
import { SectionAttr } from './sectionAttr/sectionAttr';
import { SectionAreasComunes } from './sectionAreasComunes/sectionAreasComunes';
import { SectionInteriores } from './sectionInteriores/sectionInteiores';
import { SectionRecorrido2 } from './sectionRecorrido/sectionRecorrido2';
import { SectionTipology } from './sectionTipology/sectionTipology';
import { SectionMap } from './sectionMap/sectionMap';
import { SectionForm } from './sectionForm/sectionForm';
import { FloatForm } from './floatForm/floatForm';
import { useEffect, useMemo } from 'react';
import { initAnimation } from './animation';
import './animation.scss';
import Whatsapp from '../../components/whatsapp/whatsapp';
import { onSubmit } from '../../handleSubmit/handleSubmitSantaBeatriz';
import Modal from '../../components/modal/modal';
export const MainSantaBeatriz = () => {
	const data = 0;
	useEffect(() => {
		// initAnimation({ id: '.bg-section' });
	}, [data]);
	return (
		<div className='santa-beatriz inicio'>
			<FloatForm />

			<Whatsapp submitForm={onSubmit}></Whatsapp>
			<SliderInicio images={slider} />
			<SectionRecorrido></SectionRecorrido>
			<div className='bg-section'>
				<img className='points' src={require('../../Assets/images/inicio/iconos/background-points.png')}></img>
				<SectionRender></SectionRender>
				<SectionAttr></SectionAttr>
			</div>
			<SectionAreasComunes></SectionAreasComunes>
			<SectionInteriores />
			<SectionRecorrido2></SectionRecorrido2>
			<SectionTipology />
			<SectionMap />
			<SectionForm></SectionForm>
		</div>
	);
};
