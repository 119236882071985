import React, { Component } from 'react'
import './AtencionAlCliente.scss'
import $ from '../../library/library'
export default class AtencionAlCliente extends Component {
    componentDidMount(){
        try {
            document.querySelector("html").scrollTop = 0
            document.querySelector(".Navbar").classList.add("inactive")
            $(".link").removeClass("active")
            
        } catch (error) {
            
        }
    }
    render() {
        return (
            <main className="Main-contacto contactanos">
                <section className="section-header">
                    <h1 className="title title-1 text-center white">CÓDIGO DE PROTECCION AL CONSUMIDOR</h1>
                </section>
                <div className="Proteccion-al-consumidor">
                    <div className="background-top"></div>
                    <h2 className="sub-title bold">RESIDENCIAL</h2>
                    <ul><li className="c-secondary list">Helio</li></ul>
                    <h2 className="sub-title bold">PROTECCIÓN AL CONSUMIDOR</h2>
                    <p className="paragraph"> 
                        Inversiones Padova SAC (RUC No. 20523824598) es un proveedor inmobiliario que inició sus actividades en el año 2009 quien es titular de la marcInversiones Padova Sac (RUC No. 20523824598) es un proveedor inmobiliario que inició sus actividades en el año 2009 quien es titular de la marca Padova Inmobiliaria. A la fecha ha desarrollado más de 5 proyectos a nivel nacional. El proyecto inmobiliario Helio Edificio se desarrolla por la empresa Inversiones Saletti S.A.C (RUC No. 20604455440) con domicilio en Jr. Las Poncianas N°139, Int. 201, La Molina, que forma parte del Grupo Padova Inmobiliaria.                    </p>
                    <p className="paragraph"> 
                        Las características del proyecto son las contenidas en la memoria descriptiva y lista de acabados. El proyecto Inmobiliario cumple con la condición sismo resistente según norma técnica vigente.                     </p>
                    <p className="paragraph"> 
                        Para los fines pertinentes, se le informa de la existencia de: (a) Central de Información de Promotores Inmobiliarios y/o Empresas Constructoras de Unidades Inmobiliarias del Ministerio de Vivienda, Construcción y Saneamiento; (b) Registro de infracciones y sanciones del INDECOPI y (c) Portal Mira A Quien le Compras.
                    </p>
                    
                    <p className="paragraph"> 
                    Cualquier consulta, queja o reclamo sírvase comunicar al correo <a className="mail c-secondary bold" href="mailto:helio@padovasac.com"> helio@padovasac.com </a> además de tener a su disposición el Libro de Reclamaciones en la sala de ventas del proyecto. 
                    Mayor información sobre el proceso de compra según Ley N° 29571 y/o consulte la web: <a className="mail c-secondary bold" href="https://www.helio.pe/" target="__blank"> www.helio.pe</a>  Imágenes, planos, áreas y medidas son referenciales sujetas a cambios según diseño del proyecto. No incluye muebles.

                    </p>
                    <h2 className="sub-title bold">REGISTRO DE INFRACCIONES Y SANCIONES</h2>
                    <a href="https://servicio.indecopi.gob.pe/appCPCBuscador/" target="__blank" className="mail c-secondary bold">
                        http://servicio.indecopi.gob.pe/appCPCBuscador/
                    </a>
                    <h2 className="sub-title bold">CANALES DE ATENCIÓN DE CONSULTAS, QUEJAS O RECLAMOS</h2>
                    <a href="mailto:helio@padovasac.com" className="mail c-secondary bold">helio@padovasac.com</a>
                </div>
            </main>
        )
    }
}
