import React, { Component } from 'react';
import MediaQuery from 'react-responsive';
import './AboutUs.scss';
import OwlCarousel from "react-owl-carousel2"
import {
    LightgalleryProvider,
    LightgalleryItem,
} from "react-lightgallery";
import Slider from "react-slick";
import $ from 'jquery'
// import $ from "jquery";
window.$ = $
window.jQuery = window.$

const options = {
    items: 2,
    rewind: true,
    loop:true,
    nav:true,
    center:true,
    smartSpeed:100,
    dots:true,
    margin:15,
    responsive:{
        0:{
            items:2,
            margin:20,
            autoWidth:true,
        },
        700:{
            margin:0
        }
    }
};

const events = {
    onChanged: function(e){
    }
};
export default class AboutUs extends Component {
    constructor(props){
        super(props)
        this.properties = this.props.properties
        console.log(this.properties)
    }

    componentDidMount(){
        document.querySelector("html").scrollTop = 0
        $(".link").removeClass("active")
        $("#Nosotros").addClass("active")
    }
    render() {
        const settings2 = {
            dots: true,
            // className: "slider variable-width",
            centerMode: true,
            speed: 100,
            variableWidth: true,
            slidesToShow: 2,
            centerPadding: "0vw",
            beforeChange: function(current, next){
                console.log("this current: ",current)
                console.log("this next: ", next)
            },
        };
        return (
            <main className="Main-contacto AboutUs">
                <section className="section-header">
                </section>
                <div className="Proteccion-al-consumidor">
                    <h1 className="sub-title bold text-center">¿QUIÉNES SOMOS?</h1>
                    <p className="paragraph text-center">Somos una inmobiliaria con más de 11 años de experiencia creando hogares para las familias peruanas.</p>
                    <p className="paragraph text-center">Nuestro compromiso como empresa es superar las expectativas del cliente con el desarrollo de proyectos de alto valor e innovación, estamos comprometidos con la entrega de departamentos enfocados en una buena distribución para mejorar la calidad de vida de nuestros clientes.</p>
                </div>
                <MediaQuery query="(max-width: 740px)">
                <Slider {...settings2} ref={c => (this.slider = c)} className="Slider-characteristic">
                        <div className="content-slide-quality">
                            <div className="slide-quality">
                                <div className="content-img">
                                    <img src={require("../../Assets/images/inicio/iconos/iconos-info/ruta.png")}></img>
                                </div>
                                <div className="info-quality">
                                    <span className="title-3 bold c-green">+40,000 m²</span>
                                    <p className="paragraph">Construidos</p>
                                </div>
                            </div>
                        </div>
                        <div className="content-slide-quality">
                            <div className="slide-quality">
                                <div className="content-img">
                                    <img src={require("../../Assets/images/inicio/iconos/iconos-info/experiencia.png")}></img>
                                </div>
                                <div className="info-quality">
                                    <span className="title-1 secondary bold">+11</span>
                                    <p className="paragraph">Años de experiencia</p>
                                </div>
                            </div>
                        </div>
                        <div className="content-slide-quality">
                            <div className="slide-quality">
                                <div className="content-img">
                                    <img src={require("../../Assets/images/inicio/iconos/iconos-info/familia.png")}></img>
                                </div>
                                <div className="info-quality">
                                    <span className="title-1 bold c-yellow">+600</span>
                                    <p className="paragraph">Propietarios</p>
                                </div>
                            </div>
                        </div>
                    </Slider>
                    </MediaQuery>
                    <MediaQuery query="(min-width: 740px)">
                        <div className="content-target">
                                <div className="content-slide-quality">
                                    <div className="slide-quality">
                                        <div className="content-img">
                                            <img src={require("../../Assets/images/inicio/iconos/iconos-info/experiencia.png")}></img>
                                        </div>
                                        <div className="info-quality">
                                            <span className="title-1 secondary bold">+11</span>
                                            <p className="paragraph">Años de experiencia</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-slide-quality">
                                    <div className="slide-quality">
                                        <div className="content-img">
                                            <img src={require("../../Assets/images/inicio/iconos/iconos-info/familia.png")}></img>
                                        </div>
                                        <div className="info-quality">
                                            <span className="title-1 bold c-yellow">+600</span>
                                            <p className="paragraph">Propietarios</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="content-slide-quality">
                                    <div className="slide-quality">
                                        <div className="content-img">
                                            <img src={require("../../Assets/images/inicio/iconos/iconos-info/ruta.png")}></img>
                                        </div>
                                        <div className="info-quality">
                                            <span className="title-3 bold c-green">+40,000 m²</span>
                                            <p className="paragraph">Construidos</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </MediaQuery>
                <h1 className="title bold text-center white">NUESTROS PROYECTOS</h1>
                <div className="content-title">
                    <img className="background-white movil" src={require("../../Assets/images/inicio/iconos/background-interiores.png")}></img>
                    <img className="background-white desktop" src={require("../../Assets/images/inicio/iconos/background-aboutus.png")}></img>
                </div>
                <div className="b-white content-owlcarousel">
                <LightgalleryProvider lightgallerySettings={{share:true}} galleryClassName="my_custom_classname">
                    <OwlCarousel ref="car" options={options} events={events} className="owl-carousel MyCarouselHorizontal owl-theme owl-loaded owl-drag" id="carousel-interiores">
                        {
                            this.properties.proyectos.map((item,index)=>{
                                return(
                                    <LightgalleryItem key={index} group="any" src={item.img} subHtml={"#interior"+index}>
                                        <div  className="content-slide-interiores">
                                            <div>
                                                <div className="slide-interiores">
                                                    <div className="content-img">
                                                        <img src={item.img}></img>
                                                    </div>
                                                </div>
                                                <div className="hidden" id={"interior"+index}>
                                                    <h4>{item.title}</h4>
                                                </div>
                                            </div>
                                            <div className="title-slide">
                                                <h4 className="secondary text-center title-card ">{item.title}</h4>
                                                <span className="d-block text-center sub-title-card medium">{item.subtitle}</span>
                                                <span className="d-block text-center sub-title-card light">{item.distrito}</span>
                                            </div>
                                        </div>
                                    </LightgalleryItem>
                                )
                            })
                        }
                    </OwlCarousel>
                </LightgalleryProvider>
                </div>
            </main>
        )
    }
}
