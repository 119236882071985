import { options } from '../../config';
import { SliderArea } from '../component/slider';
import './areas.scss';
export const SectionInteriores = () => {
	const comunes = [
		{
			img: require('../../../Assets/images/inicio/main/santabeatriz/interiores/compress-dormitorio-principal.webp'),
			title: 'Dormitorio principal',
		},
		{
			img: require('../../../Assets/images/inicio/main/santabeatriz/interiores/compress-dormitorio-secunadario.webp'),
			title: 'Dormitorio secundario',
		},
		{
			img: require('../../../Assets/images/inicio/main/santabeatriz/interiores/compress-sala.webp'),
			title: 'Sala',
		},
		{
			img: require('../../../Assets/images/inicio/main/santabeatriz/interiores/compress-cocina.webp'),
			title: 'Cocina',
		},
	];
	return (
		<section className='Gallery areas-comunes section-interiores' id='section-areas-interiores'>
			<div className='content-title content-title-interiores'>
				<div className='movil background-font'></div>
				<img className='underline_1 areas-comunes' src={require('../../../Assets/images/inicio/main/santabeatriz/backgrounds/los-mejores.png')}></img>
				<span className='title title-1 text-center d-block '>
					<span className='c-primary Matahari-400Regular'>ACABADOS</span> PARA TU HOGAR{' '}
				</span>
			</div>
			<p className='paragraph'>
				<p className='paragraph-desktop'>
					Los ambientes de los departamentos están pensados según la tendencia de los nuevos estilos de vida, con espacios funcionales e innovadores. Contarán con balcones que te harán
					sentir libre y acabados duraderos.
				</p>
			</p>
			<SliderArea images={comunes} icon={false}></SliderArea>
			<div className='c_p w-full'>
				<img className='w-full absolute' src={require('../../../Assets/images/inicio/underline/trazo-top.svg').default}></img>
			</div>
		</section>
	);
};
