import Navbar from '../../components/Navbar/navbar';
import Footer from '../../components/footer/footer';

export const ContainProjectCercado = props => {
	return (
		<>
			<Navbar></Navbar>
			{props.children}
			<Footer></Footer>
		</>
	);
};
