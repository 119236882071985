import React, { Component } from 'react';
import { connect } from 'react-redux';
import './footer.scss';
class Footer extends Component {
	render() {
		return (
			<footer className={`footer ${this.props.value.navShow == true ? '' : 'none'}`}>
				<div className='flat'>
					<img className='footer-secondary movil' src={require('../../Assets/images/inicio/iconos/background-footer-movil.png')}></img>
					<img className='footer-secondary desktop' src={require('../../Assets/images/inicio/iconos/background-footer-desktop.png')}></img>
					<img className='footer-white' src={require('../../Assets/images/inicio/iconos/background-footer.png')}></img>
					<div className='content-address'>
						<a target='__blank' href='https://goo.gl/maps/9r5andpdvATPiu1o9' className='white bold'>
							<span className='icon site'></span>
							<span className='light white address'>
								<span className='bold d-block '>Av. Venezuela 3258,</span>Cercado de lima
							</span>
						</a>
						<p target='__blank' className='white bold'>
							<span className='icon phone'></span>
							<a href='tel:(+51) 998  160 133' className='c-white bold'>
								998 160 133{' '}
							</a>
							 / 
							<a href='tel:(+51) 946 059 207' className='c-white bold'>
								{' '}
								946 059 207
							</a>
						</p>
						<a target='__blank' className='white bold' href='mailto:helio@padovasac.com'>
							<span className='icon email bold'></span>helio@padovasac.com
						</a>
					</div>
					<div className='content-img'>
						<a href='https://www.padova.pe/' target='__blank'>
							<img src={require('../../Assets/images/landing/brand-padova.png')}></img>
						</a>
					</div>
				</div>
				<div className='content-all'>
					<div className='container'>
						<div className='link-pages'>
							<div className='d-flex d-flex-mobile'>
								<span className='link white d-flex flex-column text-center'>
									<a href='https://padova.pe/transparencia/' target='_blank' rel='noopener noreferrer' className='white'>
										Código de protección al consumidor
									</a>
									<a href='/politicas' className='white'>
										Políticas de privacidad
									</a>
									<a className='white d-block text-center'>
										Términos y condiciones{' '}
										<a className='bold white' href='/terminos-y-condiciones' target='_blank'>
											aquí
										</a>
									</a>
								</span>
								<a className='booking'>
									<img className='' alt='libro de reclamaciones' src={require('../../Assets/icons/libro-de-reclamaciones.jpg')}></img>
								</a>
							</div>
							<span className='white d-block text-center line-footer'>
								©2020 Helio. Todos los derechos reservados. Created with ♥{' '}
								<a className='white' href='https://www.formulaperu.com/'>
									Fórmula
								</a>
							</span>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}
const MapStateProps = state => {
	return {
		value: state.routesFeatures,
	};
};
export default connect(MapStateProps)(Footer);
