import { options } from '../../config';
import { SliderArea } from '../component/slider';
import './areas.scss';
export const SectionAreasComunes = () => {
	const comunes = [
		{
			img: require('../../../Assets/images/inicio/main/santabeatriz/areas-comunes/zona-de-parrillas.png'),
			title: 'Zona de parrillas',
			icon: 'icon-terraza',
		},
		{
			img: require('../../../Assets/images/inicio/main/santabeatriz/areas-comunes/lobby.png'),
			title: 'Lobby',
			icon: 'icon-lobby',
		},
		{
			img: require('../../../Assets/images/inicio/main/santabeatriz/areas-comunes/salon-de-usos-multiples.png'),
			title: 'Salón de usos múltiples',
			icon: 'icon-salon',
		},
		{
			img: require('../../../Assets/images/inicio/main/santabeatriz/areas-comunes/patio-interior.png'),
			title: 'Patio interior',
			icon: 'icon-patio',
		},
		{
			img: require('../../../Assets/images/inicio/main/santabeatriz/areas-comunes/lavanderia.png'),
			title: 'Lavandería',
			icon: 'icon-lavanderia',
		},
		{
			img: require('../../../Assets/images/inicio/main/santabeatriz/areas-comunes/gym.png'),
			title: 'Gym',
			icon: 'icon-gym',
		},
	];
	return (
		<section className='Gallery areas-comunes' id='section-areas-comunes'>
			<img className='globo-5 globe-right' src={require('../../../Assets/images/landing/globo-11.png')}></img>
			<img className='globo-6 globe-left' src={require('../../../Assets/images/landing/globo-6.png')}></img>

			<div className='content-title idx-content-title-comunes'>
				<img className='background-white movil' src={require('../../../Assets/images/inicio/iconos/background-interiores.png')}></img>
				<img className='background-white desktop' src={require('../../../Assets/images/inicio/iconos/background-top-desktop.png')}></img>
				<div className='movil background-font'></div>
				<span className='title title-1 text-center d-block '>ÁREAS PARA QUE </span>
				<img className='underline areas-comunes' src={require('../../../Assets/images/inicio/main/santabeatriz/backgrounds/nunca-dejes-de-celebrar.png')}></img>
			</div>
			<p className='paragraph idx_paragraph-comunes'>
				<p className='paragraph-desktop'>
					¡La diversión comienza en nuestras exclusivas áreas comunes! En Helio contarás con espacios para celebrar los momentos más importantes de tu vida junto a los que más quieres. Estas
					áreas están diseñadas para elevar la vida de cada familia con diferentes actividades sin salir de casa.
				</p>
			</p>
			<SliderArea images={comunes} icon={true}></SliderArea>
		</section>
	);
};
