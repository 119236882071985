"use strict";

class Library{
    constructor(selector){
        this.element = document.querySelectorAll(selector);
        this.getProps.bind(this)
    }
    getProps(){
        return this.element
    }
    text(text){
        this.element.forEach(item => {
            item.textContent = text; 
        });
    }
    click(callback){
        this.element.forEach(item => {
            if(item.addEventListener){
                item.addEventListener("click",function(event){
                    // console.log(this)
                    let call = function(e){
                        return e
                    }
                    callback(call(event))
                }); 
            }else if (item.attachEvent){
                item.attachEvent('onclick',function(e){
                    callback(e)
                }); 
            }
        });
    }
    addClass(element){
        this.element.forEach(item => {
            item.classList.add(element) 
        });
        return true
    }
    removeClass(element){
        this.element.forEach(item => {
            item.classList.remove(element) 
        });
        return true;
    }
    scrollMove(callback){
        this.element.forEach(item =>{
            item.onscroll = ()=>{
                    
            }
        })
    }
    offset(){
        try { 
            let top = this.element[0].getBoundingClientRect().top + window.scrollY 
            let left = this.element[0].getBoundingClientRect().left
            return {top:top,left: left}
        }catch(err){
            console.log(err.message)
            return "dosnt exist"
        }
    }
    windowScroll(callback){
        window.addEventListener('scroll',function(){
            callback()
        })
    }
    attr(nameAttribute,attribute){
        this.element.forEach((item)=>{
            item.setAttribute(nameAttribute,attribute);
        })
    }
    toggleClass(className){
        this.element.forEach((item)=>{
            item.classList.toggle(className)
        })
    }
    height(){
        let height = this.element[0].getBoundingClientRect().height
        return height
    }
}
var $ = function(selector){
    let object = new Library(selector)
    return object;
}


export default $;
