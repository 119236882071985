import React, { Component, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
// import Landing from './components/Landing/landing';
import Inicio from './Pages/cercado-de-lima/inicio/inicio.js';
import Navbar from './components/Navbar/navbar';
import Popup from './components/popup/popup';
import Footer from './components/footer/footer.js';
import inicio from './properties/properties';
// import AtencionAlCliente from './Pages/AtencionAlCliente/AtencionAlCliente.js';
import AboutUs from './Pages/AboutUs/AboutUs.js';
import about from './properties/about';
import Whatsapp from './components/whatsapp/whatsapp.js';
import Posterga from './components/posterga/posterga';
import Politicasdeprivacidad from './Pages/politicasdeprivacidad/politicasdeprivacidad.js';

import Avancedeobra from './Pages/Avancedeobra/Avancedeobra.js';
import { MainInicio } from './Pages/main/MainInicio.js';
import { ContainProjectCercado } from './Pages/containProject/containProjects.js';
import LibrodeReclamacion from './Pages/libroDeReclamacion/librodeReclamacion.js';
import AtencionAlCliente from './Pages/AtencionAlCliente/AtencionAlCliente.js';
import { MainSantaBeatriz } from './Pages/main/SantaBeatriz.js';
import { onSubmit } from './handleSubmit/handleSubmitSantaBeatriz.js';
import { submitFormCercado } from './handleSubmit/handleSubmitCercado.js';
import { ContainSantaBeatriz } from './Pages/containProject/containSantaBeatriz.js';
// const AboutUs =  React.lazy(() => import(/* webpackChunkName:"aboutUs"*/ './Pages/AboutUs/AboutUs.js'));

export default function AppRouter() {
	/**
	 *
	 * @param {Event} e
	 */
	const closePopUp = ({ target }) => {
		/**
		 * @type {HTMLElement}
		 */
		const popup = target.closest('.popup__wrapper');
		popup.classList.add('--closed');
		popup.remove();
	};
	return (
		<div className='Main-pages'>
			<Router>
				<Switch>
					{/* <Route exact path='/'>
						<MainInicio></MainInicio>
					</Route> */}
					<Route exact path='/'>
						<ContainSantaBeatriz>
							<MainSantaBeatriz />
						</ContainSantaBeatriz>
					</Route>
					<Route exact path='/cercado-de-lima'>
						<ContainProjectCercado>
							<Inicio properties={inicio} />
						</ContainProjectCercado>
					</Route>
					<Route exact path='/quienes-somos'>
						<ContainProjectCercado>
							<AboutUs properties={about}></AboutUs>
						</ContainProjectCercado>
					</Route>
					<Route exact path='/avance-de-obra'>
						<ContainProjectCercado>
							<Avancedeobra></Avancedeobra>
						</ContainProjectCercado>
					</Route>

					<Route exact path='/terminos-y-condiciones'>
						<Posterga></Posterga>
					</Route>
					<Route exact path='/politicas'>
						<Politicasdeprivacidad></Politicasdeprivacidad>
					</Route>

					<Route exact path='/codigo-de-proteccion-al-consumidor'>
						<Suspense fallback={<div>Loading...</div>}>
							<LibrodeReclamacion></LibrodeReclamacion>
						</Suspense>
					</Route>
					<Route exact path='/atencion-al-cliente'>
						<Suspense fallback={<div>Loading...</div>}>
							<AtencionAlCliente></AtencionAlCliente>
						</Suspense>
					</Route>
					<Route exact path='/*'>
						<Redirect to='/' />
					</Route>
				</Switch>

				{/* </MediaQuery> */}
			</Router>
		</div>
	);
}
