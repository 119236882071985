import NavbarSantaBeatriz from '../../components/Navbar/NavbarSantaBeatriz';
import Navbar from '../../components/Navbar/navbar';
import Footer from '../../components/footer/footer';
import FooterSantaBeatriz from '../../components/footer/footerSantaBeatriz';

export const ContainSantaBeatriz = props => {
	return (
		<>
			<NavbarSantaBeatriz></NavbarSantaBeatriz>
			{props.children}
			<FooterSantaBeatriz></FooterSantaBeatriz>
		</>
	);
};
