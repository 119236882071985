const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    slider:[
    ]
}

export const sliderMain = createSlice({
    name:'sliderMain',
    initialState,
    reducers:{

    }
})

export default sliderMain.reducer
