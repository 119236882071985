import React, { Component } from 'react'
import "./libroDeReclamacion.scss"
export default class LibrodeReclamacion extends Component {
    render() {
        return (
            <main className="content-info-all">
                <div className="container">
                    <h1 className="title secondary d-block m">CÓDIGO DE PROTECCIÓN AL CONSUMIDOR</h1>
                    <p className="paragraph">En aplicación de lo establecido por la Ley 29571, Código de Protección y Defensa del Consumidor y sus normas modificatorias, la vendedora, cumple con brindarle la siguiente información:</p>
                    <h2 className="sub-title">
                        ANTECEDENTES Y COMPORTAMIENTO DEL PROVEEDOR
                    </h2>
                    <p className="paragraph">
                    • Inversiones Padova S.A.C. es un proveedor inmobiliario que inició sus actividades en el año 2010 quien es el titular de la marca Inversiones Saletti Sac. A la fecha ha desarrollado más de 05 proyectos a nivel nacional, cuyos datos se señalan a continuación
                    </p>
                    <h2 className="sub-title">
                        IDENTIFICACIÓN DEL PROVEEDOR INMOBILIARIO
                    </h2>
                    <ul className="list">
                        <li>Empresa: Inversiones Padova S.A.C. </li>
                        <li>RUC: 20523824598 </li>
                        <li>Partida registral N°12391072 del Registro de Personas Jurídicas de Lima</li>
                        <li>Dirección: Jr. Las Poncianas No. 139, Oficina 201, La Alameda de la Molina Vieja, La Molina</li>
                        <li>Teléfono de contacto: 495-1331 </li>
                        <li>Página web: <a className="link" href="https://www.padova.pe/" target="__blank">www.padova.pe</a></li>
                        <li>Representante Legal: José Rodolfo Bragagnini Zamora</li>
                        <li>Los documentos de identificación del proveedor inmobiliario pueden ser descargados en los siguientes links:  
                            <a className="link" target="__blank" href="/Literal-Partida-Inmueble-Venezuela.pdf">Copia literal de Padova </a>, 
                            <a className="link" target="__blank" href="/Saletti-Vigencia-de-poder-22-06.pdf">Vigencia de Poder Padova</a>,  
                            <a className="link" target="__blank" href="/Saletti-RUC-Actualizado.pdf">Ficha Ruc Padova</a> ;
                             además pueden ser solicitados de manera gratuita en nuestra Sala de Ventas o a través del correo electrónico: 
                            <a className="link" href="mailto:servicioalcliente@padovasac.com">servicioalcliente@padovasac.com</a>
                        </li>
                    </ul>
                    <h2 className="sub-title">DESCRIPCIÓN DEL PROYECTO</h2>
                    <p className="">- <strong>Nombre del Proyecto</strong>: Helio</p>
                    <p className="paragraph">-<strong>Dirección</strong>: Avenida Venezuela 3258 Cercado de Lima.  Provincia y Departamento de Lima.</p>
                    <p className="paragraph">-<strong>Anteproyecto</strong>: El proyecto cuenta con Anteproyecto aprobado por la municipalidad Distrital de Cercado de Lima, según expediente N°724-2015. </p>
                    <p className="paragraph">-<strong>Terreno Matriz</strong>: La propiedad del terreno matriz sobre el cual se edificará el proyecto corre inscrita en la Partida N°11049031 del Registro de Predios de Lima, En el siguiente link podrá obtener de manera gratuita la Copia literal del terreno donde se llevará a cabo el proyecto:
                      <a target="__blank" href="/CRI-VENEZUELA.pdf"className="link">
                        Terreno Matriz.
                    </a>
                     </p>
                    <p className="paragraph">-<strong>Cantidad de Unidades</strong>: 99 departamentos distribuidos en 1 edificios uno. Este número podrá cambiar en el transcurso del desarrollo del proyecto, conforme al diseño y a la normativa vigente.</p>
                    <p className="paragraph">-<strong>Características relevantes del Proyecto:</strong>: 99 departamentos distribuidos en 1 edificios uno. Este número podrá cambiar en el transcurso del desarrollo del proyecto, conforme al diseño y a la normativa vigente.
                        <span className="d-block">o	Áreas de Propiedad Exclusiva:</span>
                        <ul className="sub-list">
                            <li>Departamentos de 1, 2, 3 dormitorios desde 37.50 m2</li>
                            <li>Flats con balcón </li>
                            <li>Estacionamientos simples</li>
                        </ul>
                        <p className="paragraph">
                            Las áreas de las unidades inmobiliarias varían según tipología y están sujetas a la vista y ubicación de cada unidad. Puede obtener información de la tipología de su interés de manera gratuita en nuestra Sala de Ventas o a través del correo electrónico: <a className="link" href="mailto:servicioalcliente@padovasac.com">servicioalcliente@padovasac.com</a>
                        </p>
                        <span className="d-block">o	Áreas de Propiedad Común:</span>
                        <ul className="sub-list">
                            <li>Lobby</li>
                            <li>Ascensores (2)</li>
                            <li>Ascensor</li>
                            <li>4 parrillas</li>
                            <li>02 terrazas</li>
                            <li>Jardín</li>
                            <li>Salón de usos múltiples</li>
                            <li>Salón de niños</li>
                        </ul>
                    </p>

                    <p className="paragraph"><strong>- Acabados de inmueble:</strong>
                        <span className="d-block">o	SALA – COMEDOR</span>
                        <ul className="sub-list">
                            <li>PUERTA DE INGRESO: Puerta contra placada de MDF pintada color blanco y marco de madera color blanco.</li>
                            <li>PISO: laminado</li>
                            <li>CONTRAZOCALO: madera color madera o similar.</li>
                            <li>PARED: Empastado y papel mural.</li>
                            <li>TECHO: Empastado, pintura blanca temple y sellador.</li>
                            <li>VENTANA: Vidrio simple con carpintería de aluminio.</li>
                        </ul>                                            
                        <span className="d-block">o	DORMITORIO PRINCIPAL</span>
                        <ul className="sub-list">
                            <li>PUERTA: Puerta contra placada de MDF pintada color blanco y marco de madera color blanco.</li>
                            <li>PISO: laminado</li>
                            <li>CONTRAZOCALO: madera color madera o similar.</li>
                            <li>PARED: Empastado y papel mural.</li>
                            <li>TECHO: Empastado, pintura blanca temple y sellador.</li>
                            <li>VENTANA: Vidrio simple con carpintería de aluminio.</li>
                            <li>CLOSET:  de melamine color blanco con tiradores</li>
                        </ul>                        
                        <span className="d-block">o	DORMITORIO SECUNDARIO</span>
                        <ul className="sub-list">
                            <li>PUERTA: Puerta contra placada de MDF pintada color blanco y marco de madera color blanco.</li>
                            <li>PISO: laminado</li>
                            <li>CONTRAZOCALO: madera color madera o similar.</li>
                            <li>PARED: Empastado y papel mural.</li>
                            <li>TECHO: Empastado, pintura blanca temple y sellador.</li>
                            <li>VENTANA: Vidrio simple con carpintería de aluminio.</li>
                            <li>CLOSET:  de melamine color blanco con tiradores</li>
                        </ul>                        
                        <span className="d-block">o BAÑO:</span>
                        <ul className="sub-list">
                            <li>PUERTA: Puerta contra placada de MDF pintada color blanco y marco de madera color blanco.</li>
                            <li>PISO: Cerámico color marfil o similar.</li>
                            <li>CONTRAZOCALO: Cerámico color marfil o similar.</li>
                            <li>ZOCALO: Cerámico color marfil o similar (solo en ducha).</li>
                            <li>PARED: Pintura látex color blanco.</li>
                            <li>TECHO: Empastado, pintura blanca temple y sellador.</li>
                            <li>INODORO: Inodoro blanco.</li>
                            <li>LAVATORIO: Lavatorio empotrado en granito</li>
                            <li>GRIFERIA LAVATORIO: Llave agua fría.</li>
                            <li>GRIFERIA DUCHA: Mezcladora ducha agua fría y caliente.</li>
                        </ul>                        
                        <span className="d-block">o COCINA:</span>
                        <ul className="sub-list">
                            <li>PUERTA: Puerta contra placada de MDF pintada color blanco y marco de madera color blanco.</li>
                            <li>PISO: Cerámico color crema o similar.</li>
                            <li>CONTRAZOCALO: Cerámico color gris o similar.</li>
                            <li>ZOCALO: Cerámico color marfil o similar (solo sobre lavadero de cocina).</li>
                            <li>PARED: Pintura látex color blanco.</li>
                            <li>TECHO: Empastado, pintura blanca temple y sellador.</li>
                            <li>MOBILIARIO: Mueble de melamine color blanco con tiradores y tablero granito sal y pimienta</li>
                            <li>LAVADERO COCINA: Lavadero de 1 poza de acero inoxidable.</li>
                            <li>GRIFERIA LAVATORIO: Llave agua fría.</li>
                            <li>GRIFERIA COCINA: Llave agua fría</li>
                        </ul>                        
                        <span className="d-block">o	LAVANDERIA: </span>
                        <ul className="sub-list">
                            <li>Dentro del departamento</li>
                        </ul>                        
                    </p>
                    <p className="paragraph">La Memoria Descriptiva del Proyecto, así como la Memoria de Acabados completa de las unidades, pueden ser solicitadas de manera gratuita en nuestra Sala de Ventas o a través del correo electrónico: 
                        <a className="link" href="mailto:helio@padovasac.com" target="__blank">helio@padovasac.com</a></p>
                    <p><strong className="d-block">Notas</strong>
                    <ul className="list-numeric">
                        <li>Todos los acabados están sujetos a stock y se podrán reemplazar por uno similar.</li>
                        <li>No incluye luminarias, espejos, therma, puertas de duchas, decoración, ni gras en jardines.</li>
                        <li>Las imágenes, planos, que se muestran en nuestros diferentes folletos así como en nuestra página web, son meramente referenciales, por lo que se podrán presentar modificaciones y/o contener elementos de apreciación estética y otros que no comprometen a la empresa promotora.</li>
                        <li><strong>Precio:</strong> Los precios de venta, ofertas, así como sus vigencias, pueden ser solicitados de manera gratuita a través del formulario del proyecto en la página web mediante el siguiente link <a href="https://padova.pe/proyectos/helio" className="link" target="__blank"></a>https://padova.pe/proyectos/helio , a través del correo electrónico helio@padovasac.com  o en la Sala de Ventas. Éstos son referenciales y se encuentran sujetos a posibles variaciones y stock. </li>
                    </ul>                    
                    </p>

                    <p className="paragraph">
                        El precio debe ser cancelado en la moneda estipulada en el contrato, salvo acuerdo distinto de las partes.
                    </p>
                    <p className="paragraph">
                        Salvo la penalidad por resolución, la Vendedora no cobra intereses ni comisiones, adicionales. 
                        Esto no es de aplicación en caso de demora en el pago del precio de venta, en la cual se aplicará, sin perjuicio de la constitución en mora, un interés compensatorio y moratorio mensual equivalente al 1.5% en moneda nacional o un interés compensatorio y moratorio mensual equivalente al 1% en moneda extranjera, intereses que se devengarán desde el día siguiente de producido el incumplimiento. 
                    </p>

                    <p className="paragraph">
                        <strong>Cronograma de Pagos: </strong>
                        El Cronograma de pagos del Precio de las unidades estará sujeto a la negociación entre el Comprador y el Vendedor, el cual será reflejado en el respectivo Contrato de Compraventa.
                    </p>
                    <p className="paragraph">En caso de financiamiento directo, El Comprador suscribirá una letra por cada pago financiado. La Empresa no cobra intereses ni comisiones en el caso de financiamiento directo.</p>
                    {/*  */}
                    <p className="paragraph">
                        <strong>Medios de pago permitidos: </strong>
                        Los medios de pago permitidos para cancelar el precio de venta son: Cheque de Gerencia No Negociable y/o Transferencia/Depósito en la cuenta recaudadora de la Vendedora, la cual será informada al Comprador a la firma del Contrato de Compraventa. 
                    </p>
                    <p className="paragraph">De forma excepcional, previa autorización escrita de la Vendedora, podrán utilizarse otros medios de pago tales como, el uso de tarjetas de crédito o débito, u otros que el Comprador considere. En caso se genere un recargo adicional y/o pago de comisión por el monto pagado, éste será asumido por el Comprador. </p>
               
                    <p className="paragraph">
                        <strong>Medios de pago permitidos: </strong>
                        El comprador asume todos los gastos notariales y registrales que se deriven del Contrato de Compraventa, así como sus adendas. Adicionalmente, le corresponde el pago de los tributos establecidos de acuerdo a la normativa vigente, tales como Impuesto Predial y Arbitrios, entre otros. A partir de la fecha de entrega, el Comprador asume todos los pagos referentes a los inmuebles adquiridos (tributos, servicios y mantenimiento de áreas comunes), salvo el pago del Predial, el cual será de su responsabilidad a partir del 01 de enero del año siguiente de la entrega.
                    </p>
                    <p className="paragraph">En los proyectos inmobiliarios en donde se haya señalado que las unidades inmobiliarias contienen instalación interna de redes de gas natural, nuestra empresa se obliga únicamente a dejar las redes internas instaladas. Los derechos de trámite y conexión domiciliaria, los gastos por adecuación y reconversión de artefactos y/o electrodomésticos a gas natural, los gastos de instalación de ducto de gas, el pago de consumo y cualquier otro pago relacionado al Gas Natural serán asumidos íntegramente por el comprador. El suministro del gas estará sujeto a las autorizaciones y procedimientos de la empresa de Gas Natural. En caso no existan redes públicas de gas en la zona donde se desarrolla el proyecto inmobiliario, los trámites y gastos que sean necesarios para la habilitación de dichas redes, así como para su conexión con las redes internas de gas del proyecto serán asumidas y estarán a cargo del comprador en coordinación con la Junta de Propietarios</p>


                    <p className="paragraph"><strong>Proceso de Compra: </strong>
                        <span>o	Acuerdo de Separación:</span>
                             En caso de interés para la compra de una unidad el cliente deberá, previo pago del monto por derecho de separación, suscribir un Contrato de Separación, el cual tendrá las siguientes condiciones:
                        <ul className="sub-list">
                            <li><strong>Vigencia: </strong>El contrato de separación tendrá una vigencia de 15 días calendario contados a partir de la suscripción de dicho documento. </li>
                            <li><strong>Monto de derecho de separación: </strong>
                                S/ 500. Dicho monto será imputado al pago del precio de venta.
                            </li>
                            <li><strong>Formas de pago del precio total de venta: </strong>
                            En dicho documento se deberá indicar la forma en la cual el cliente pagará el precio total de venta de las unidades a ser adquiridas. Esta podrá ser cambiada y actualizada a la firma del Contrato de Compraventa. 
                            </li>
                            <li><strong>Formas de pago del precio total de venta: </strong>
                            En dicho documento se deberá indicar la forma en la cual el cliente pagará el precio total de venta de las unidades a ser adquiridas. Esta podrá ser cambiada y actualizada a la firma del Contrato de Compraventa. 
                            </li>
                            <li><strong className="d-block">Causales de Resolución: </strong>
                                <ul className="list">
                                    <li>En caso que el plazo de vigencia de la separación transcurra y el cliente no haya firmado el Contrato de Compraventa por causas imputables a éste. En este caso, la Separación quedará resuelta en forma automática de pleno derecho, sin necesidad de una comunicación previa al Comprador.</li>
                                    <li>En caso el cliente desista de la compra.</li>
                                    <li>En ambos supuestos, la Vendedora tendrá el derecho de ofrecer y vender las unidades inmobiliarias que estuvieron separadas, sin responsabilidad alguna. </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Penalidades por resolución: </strong>
                                El cliente perderá el 50% del monto cancelado por con concepto de Separación, el cual será retenido por la Vendedora en calidad de Penalidad.
                            </li>
                            <li>
                                <strong>Casos en que se devuelve el monto de la Separación: </strong>
                                La Vendedora devolverá el monto de Separación únicamente en el supuesto que el Cliente, por causas no imputables a éste, no obtenga el Crédito Hipotecario, lo cual deberá ser sustentado con una comunicación de la entidad financiera en la cual calificó.
                                <span>La devolución del monto cancelado, será efectuada dentro de los cuarenta y cinco (45) días calendarios siguientes de ocurrida la resolución del Acuerdo de Separación, mediante la entrega de un cheque de gerencia a nombre del cliente. </span>
                            </li>
                        </ul>
                    </p>
                    <p className="paragraph">
                        <strong>o Contrato de Compraventa: </strong>
                        Las partes suscribirán el Contrato de Compraventa donde se indicarán las condiciones finales pactadas entre ambas partes, con las cuales ambas están de acuerdo, así como las unidades a ser adquiridas y precio a ser cancelado. Cabe indicar que la Vendedora se reserva el derecho de propiedad de las unidades hasta que las unidades sean canceladas en su totalidad.
                        <ul className="list">
                            <li>
                                <strong className="d-block">Causales de Resolución: </strong>
                                <ul className="sub-list">
                                    <li>Por falta de pago.</li>
                                    <li>Por causas imputables a las partes.</li>
                                    <li>Por acuerdo de las partes. En este supuesto, La vendedora devolverá el 100% de los montos cancelados, en la oportunidad pactada en el contrato. Para tal efecto, suscribirán un acuerdo de resolución. Firmado dicho documento, la Vendedora tendrá el derecho de ofrecer y vender las unidades inmobiliarias que fueron objeto del Contrato, sin responsabilidad alguna. </li>
                                </ul>
                            </li>
                            <li>
                                <strong className="d-block">Penalidades por resolución: </strong>
                                La empresa retendrá el 20% del monto pagado, el cual será retenido por la Vendedora en calidad de Penalidad.
                            </li>
                        </ul>
                    </p>
                    <h2 className="sub-title">
                        SALA DE VENTAS
                    </h2>
                    <p className="paragraph"><strong>- Dirección de la Sala de Ventas: </strong>Dirección de la Sala de Ventas: Av. Venezuela 3258 Cercado de Lima</p>
                    <p className="paragraph"><strong>- Teléfono de Contacto: </strong> 962-741-942 /998-160-133</p>
                    <p className="paragraph"><strong>- Horarios de Atención: </strong>lunes a sábado de 10:00 a.m. a 5:00 p.m.</p>
                    <h2 className="sub-title">
                        ANÁLISIS SÍSMICO 
                    </h2>
                    <p className="paragraph">Los proyectos inmobiliarios de nuestro Grupo en todas sus fases cumplen con las normas técnicas, condiciones de seguridad y salud de acuerdo a la normativa vigente aplicable. Sin perjuicio de ello, nuestros proyectos son evaluados de forma estricta por las Comisiones Revisoras designadas en cada Municipalidad, lo cual es evidenciado mediante la entrega de la licencia de construcción.</p>
                    <p className="paragraph">El proyecto inmobiliario Helio se desarrollará considerando los lineamientos y parámetros de la Norma de Diseño Sismo-resistente vigente E-030 (2016). En caso de requerir mayor información al respecto esta podrá ser solicitada en nuestra caseta de ventas del proyecto. </p>
                    <h2 className="sub-title">
                        REGISTRO DE INFRACCIONES Y SANCIONES
                    </h2>
                    <p className="paragraph grid">
                        Para los fines pertinentes, se le informa de la existencia de: (a) Central de Información de Promotores Inmobiliarios y/o Empresas Constructoras de Unidades Inmobiliarias del Ministerio de Vivienda, Construcción y Saneamiento – Ley No. 29203; (b) Registro de infracciones y sanciones del INDECOPI y (c) Portal Mira A Quien le Compras: 
                        <a className="link" href="http://servicio.indecopi.gob.pe/appCPCBuscador/" target="__blank">
                            http://servicio.indecopi.gob.pe/appCPCBuscador/ 
                        </a>
                    </p>
                    <h2 className="sub-title">
                        CANALES PARA ATENCION DE CONSULTAS, QUEJAS O RECLAMOS
                    </h2>
                    <p className="paragraph">
                    Cualquier clase de consulta, queja o reclamo sírvase comunicar al correo electrónico <a className="link" target="__blank" href="mailto:helio@padovasac.com">helio@padovasac.com</a>  además de tener a su disposición el Libro de Reclamaciones correspondiente en la sala de ventas del proyecto. Mayor información sobre el proceso de compra según Ley N° 29571 y/o experiencia, así como servicios de Inversiones Padova S.A.C. consulte en www.padova.pe 
                    </p>
                </div>
            </main>
        )
    }
}
