import React from 'react';
import { createRoot } from 'react-dom/client';

import * as serviceWorker from './serviceWorker';
import './Assets/scss/index.scss';
import AppRouter from './routes';
import store from './data/store';
import { Provider } from 'react-redux';

if (window.location.search) localStorage.setItem('url_query', window.location.search);
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<AppRouter />
		</Provider>
	</React.StrictMode>
);

serviceWorker.unregister();
