import React, { Component } from 'react';
import './modal.scss';
export default class Modal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
		};
		this.hideModal = this.hideModal.bind(this);
	}
	hideModal(event) {
		try {
			let type = event.target.dataset.type;
			let modal = document.querySelector('.ga-modal');
			modal.className = 'ga-modal';
		} catch (error) {}
	}
	render() {
		return (
			<div className={'content-Modal ' + this.props.clase} onClick={this.hideModal.bind(this)} data-type='modal'>
				{this.props.children}
			</div>
		);
	}
}
