import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    dataTipologia:[
        {
            name:"tipo1",
            position:2,
            tipologias:[
                // {   
                //     name:"Tipo x03",img:require("../../Assets/images/inicio/tipologias/x-03.png"),
                //     sketch:require("../../Assets/images/inicio/tipologias/sketch/plano_x03.png"),
                //     dorms:"2 Dorms",
                //     meters:'52.80m²',
                // },
                {   
                    name:"Tipo x04",img:require("../../Assets/images/inicio/tipologias/x-04.png"),
                    sketch:require("../../Assets/images/inicio/tipologias/sketch/plano_x04.png"),
                    dorms:"2 Dorms",
                    meters:'57.67m²',
                },
                // {   
                //     name:"Tipo x05",img:require("../../Assets/images/inicio/tipologias/x-05.png"),
                //     sketch:require("../../Assets/images/inicio/tipologias/sketch/plano_x05.png"),
                //     dorms:"2 Dorms",
                //     meters:'56.15m²',
                // }
            ],
            features:[
                "Dormitorio Principal",
                "Dormitorio Secundario",
                "2 Baños",
                "Sala / Comedor",
                "Cocina / Lavandería"
            ]
        },
        {
            name:"tipo2",
            position:3,
            tipologias:[
                {
                    name:"Tipo x01",img:require("../../Assets/images/inicio/tipologias/x-01.png"),
                    sketch:require("../../Assets/images/inicio/tipologias/sketch/plano_x01.png"),
                    dorms:"3 Dorms",
                    meters:'68.51m²',
                },
                {
                    name:"Tipo x02",img:require("../../Assets/images/inicio/tipologias/x-02.png"),
                    sketch:require("../../Assets/images/inicio/tipologias/sketch/plano_x02.png"),
                    dorms:"3 Dorms",
                    meters:'65.10m²',
                }
            ],
            features:[
                "Dormitorio Principal",
                "2 Dormitorio Secundario",
                "2 Baños",
                "Sala / Comedor",
                "Cocina / Lavandería"
            ]
        },
    ],
    
    changeTypology:[2]
}

const inicio = createSlice({
    name:"inicio",
    initialState,
    reducers:{
        updateInf:(state,value)=>{
             state.changeTypology = [value.payload.num] 
        },
        
    }
})
export const { updateInf,updateInfMain} = inicio.actions
export default inicio.reducer