import './sectionrender.scss';
export const SectionRender = props => {
	return (
		<section className='section-render flex'>
			<picture>
				<source media='(min-width: 500px)' srcSet={require('../../../Assets/images/inicio/main/compress-desktop_fachada.webp')}></source>
				<img className='bg-render' src={require('../../../Assets/images/inicio/main/compress-mobile_fachada.webp')}></img>
			</picture>
			<div className='flex flex-col content-text-render'>
				<div className='bg-part'>
					<img className='img' src={require('../../../Assets/images/inicio/main/santabeatriz/backgrounds/bg-part.png')}></img>
					<div className='flex flex-col absolute'>
						<img className='img_letter' src={require('../../../Assets/images/inicio/main/santabeatriz/backgrounds/eleva.png')}></img>
						<span className='txt-letter'>tu nuevo estilo de vida</span>
					</div>
				</div>
				<div className='flex flex-col content-text'>
					<span className='text-white txt catamaran-bold text-center'>
						1 <span className='c-orange'>•</span> 2
					</span>
					<span className='text-white txt-2 semi-bold text-center leading-none'>Dormitorios</span>
					<span className='text-white text-center txt-3'>desde 28 m² hasta 51 m²</span>
					<span className='text-white text-center txt-3'>
						Ca. Enrique Villar 830,
						<br /> Santa Beatriz
					</span>
					<span className='text-white text-center txt-4 idx_desktop'>NUESTROS PROYECTOS DE ALTURA</span>
				</div>
				<div className='flex gap-2 ctn-status idx_desktop'>
					<img className='' src={require('../../../Assets/images/inicio/main/santabeatriz/icons/entregado.png')}></img>
					<img className='' src={require('../../../Assets/images/inicio/main/santabeatriz/icons/lanzamiento.png')}></img>
				</div>
			</div>
			<div className='text-white text-center txt-4 idx_mobile'>NUESTROS PROYECTOS DE ALTURA</div>
			<div className='flex gap-2 ctn-status idx_mobile'>
				<img className='' src={require('../../../Assets/images/inicio/main/santabeatriz/icons/entregado.png')}></img>
				<img className='' src={require('../../../Assets/images/inicio/main/santabeatriz/icons/lanzamiento.png')}></img>
			</div>
		</section>
	);
};
