import Swal from 'sweetalert2';
import { BaseValidatonSchema } from '../../../components/common/forms/constraints/ValidatonSchemas';
import { FormContainer, checkableBoolProps, setInputProps } from '../../../components/forms/Form';
import { initialValues } from '../../config';
import axios from 'axios';
import Modal from '../../../components/modal/modal';
import $ from 'jquery';
import { saveLead } from '../../../handleSubmit/handleSubmitSantaBeatriz';
export const SectionForm = props => {
	const closeDialog = element => {
		try {
			$('.ga-modal').removeClass('active');
		} catch (error) {}
	};
	const submitForm = async (values, formik) => {
		try {
			await saveLead(values, formik)

			let modal = document.querySelector('.ga-modal');
			modal.className = 'ga-modal active';
			formik.setSubmitting(false);
			formik.resetForm();
		} catch (error) {
			formik.setSubmitting(false);
			Swal.fire({
				title: '',
				text: 'Error, inténtalo de nuevo',
				type: 'error',
				confirmButtonText: 'Aceptar',
			});
		}
	};
	return (
		<section className='Contacto'>
			<Modal clase='ga-modal'>
				<div className='content-message d-none'>
					<span className='Secondary text-center d-block title'>¡Muchas gracias!</span>
					<span className='sub-title'>Un asesor te contactará pronto o puedes escribirle haciendo clic aquí</span>
					<a
						target='__blank'
						href='https://wa.me/51998160133?text=Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20proyecto%20Helio'
						onClick={e => {
							window.dataLayer &&
								window.dataLayer.push({
									event: 'gaEvent',
									eventCategory: 'Whatsapp - Chat',
									eventAction: 'Clic',
									eventValue: 5,
								});
						}}
						className='asesores woman'
					>
						<div className='content-img-asesor mujer'>
							{/* <img className='' src={require('../../../../assets/images/iconos/icono-woman.png')}></img> */}
							<div class='icon-user'></div>
						</div>
						<div className='hover-link'>
							<span className='title-asesor'>Asesor Helio</span>
						</div>
					</a>
					<div
						className='content-close'
						data-modal='modal'
						onClick={() => {
							closeDialog('.content-Modal');
						}}
					>
						<div className='close' data-modal='modal'></div>
					</div>
				</div>
			</Modal>
			<img className='globo-9 globe-right' src={require('../../../Assets/images/landing/globo-13.png')}></img>
			<img className='globo-11 globe-left' src={require('../../../Assets/images/landing/globe-15.png')}></img>
			<FormContainer initialValues={initialValues} validationSchema={BaseValidatonSchema} onSubmit={submitForm}>
				{form => {
					const { handleSubmit, isSubmitting } = form;
					return (
						<form className='formulario main-bottom' onSubmit={handleSubmit}>
							<div className='content-title'>
								<span className='title title-1 text-center d-block bold'>ES TIEMPO DE</span>
								<img className='underline-3' src={require('../../../Assets/images/inicio/underline/tiempo-de-alzar-vuelo.png')}></img>
							</div>
							<p className='paragraph _paragraph'>Ingresa tus datos para poder contarte más acerca del proyecto. ¡Es momento de iniciar esta nueva etapa en tu vida!</p>
							<input placeholder='Nombre*' {...setInputProps('fname', 'input', form)}></input>
							<input placeholder='Apellido*' {...setInputProps('lname', 'input', form)}></input>
							<input placeholder='Teléfono / Celular*' {...setInputProps('telephone', 'input', form)}></input>
							<input placeholder='Correo*' {...setInputProps('email', 'input', form)}></input>
							<div className='terminos'>
								<input type='checkbox' name='term' id='term' {...checkableBoolProps('terms', '', form)}></input>
								<label htmlFor='term' className='change'>
									<div className='checked'></div>
								</label>
								<span className='paragraph-term'>
									Acepto los{' '}
									<a href='/politica-de-privacidad.pdf' target='__blank' className='bold'>
										términos y condiciones
									</a>
								</span>
							</div>
							<button type='submit' className='btn-submit' disabled={isSubmitting}>
								{isSubmitting ? 'Enviando' : 'Enviar'}
							</button>
						</form>
					);
				}}
			</FormContainer>
		</section>
	);
};
