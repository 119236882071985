import axios from "axios";
import Swal from "sweetalert2";

export const saveLead = async (values, { setSubmitting, resetForm }) =>{

	let sheet_id = '';
	let project_id = '';
	// if(window.location.pathname.includes("beatriz")){
		project_id = 2015;
		sheet_id = "1PwwTPlMv0HkTLfnOsfLhUOXpdgmTfbWugSBWcCyxIDA"
	// }
	if(window.location.pathname.includes("cercado")){
		project_id = 1435;
		sheet_id = "1JVdnxXGOebKZtaSBca0Z1l105fok6FNFcGbqA1l6Uz0"
	}

	new URLSearchParams(localStorage.getItem('url_query')).forEach((v, k) => /utm_/.test(k) && (values[`${k.toLowerCase()}`] = v));
	const date = new Date().toLocaleString('es-PE', { timeZone: 'America/Lima' });

	await axios.post(
		'https://cors.formulaperu.com/https://script.google.com/macros/s/AKfycbws7GCpc1eEN5ScQ_IisUkLEwKQHvY_XCe5_KEbXA3ytUWVtA/exec',
		{
			ss_id: sheet_id,
			range: `Web!A:E`,
			values: [[date, values.fname, values.lname, values.email, values.telephone, values.utm_source, values.utm_medium, values.utm_campaign, values.utm_content, values.utm_term]],
		},
		{
			headers: {
				'X-Requested-With': 'XMLHttpRequest',
			},
		}
	).catch(error => console.log(error));

	await axios.post('https://fbleads.formulaperu.com/api/webhooks/dc5c1a81-af49-42d9-b9bd-99eb74e87ad0', {
			environment: 'mirano',
			lead: {
				IdProyecto: project_id,
				Nombres: values.fname,
				Apellidos: values.lname,
				Correo: values.email,
				Celular: values.telephone,
				Comentario: values.observation,
				url_query: localStorage.getItem('url_query'),
			},
		})
}

export const onSubmit = async (values, formik) => {
	try {
		const form = document.querySelector('.content-contacto-whatsapp');
		await saveLead(values, formik)
		form.classList.add('--success');
		formik.setSubmitting(false);
		formik.resetForm();
		form.reset();
		window.dataLayer &&
			window.dataLayer.push({event: 'dlLeadWhatsApp'});
	} catch (error) {
		formik.setSubmitting(false);
		Swal.fire({
			title: '',
			text: 'Error, inténtalo de nuevo',
			type: 'error',
			confirmButtonText: 'Aceptar',
		});
	}
};
