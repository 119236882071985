import styled from 'styled-components';
import { setInputProps } from '../../../components/forms/Form';

const InputTextStyled = styled.input`
	letter-spacing: normal;
	word-spacing: normal;
	text-shadow: none;
	text-align: start;
	cursor: text;
	border-width: 0;
	&::placeholder {
		color: #97a1ac;
		font-size: 0.9rem;
	}
	&.--invalid {
		border-color: red !important;
		& ~ .label-accept {
			border-color: red !important;
		}
		& ~ .input {
			border-color: red !important;
		}
	}
	&.--valid {
		border-color: #00cc00 !important;
		& ~ .label-accept {
			border-color: #00cc00 !important;
		}
		& ~ .input {
			border-color: #00cc00 !important;
			color: white;
		}
	}
`;

export const InputText = props => {
	const { title, name, placeholder, form, ...rest } = props;
	return (
		<div className={`content-input  ${rest?.className ? rest.className : ''}`}>
			{title && <label>{title}</label>}
			<div className={`content-sub-input ${props.icon ? 'include-icon' : ''}`}>
				<InputTextStyled type={props.type || 'text'} placeholder={placeholder || ''} {...setInputProps(name, `input`, form)} />
			</div>
		</div>
	);
};
