import { createSlice } from '@reduxjs/toolkit';
const initialState = {
	infTypology: {
		name: 'tipo2',
		tipologias: [
			{
				name: 'Tipo x03',
				dorms: '2 dorms',
				img: require('../../Assets/images/inicio/tipologias/x-03.png'),
				sketch: require('../../Assets/images/inicio/tipologias/sketch/plano_x03.png'),
				meters: '53.28m²',
			},
		],
		features: ['Dormitorio Principal', 'Dormitorio Secundario', '2 Baños', 'Sala / Comedor', 'Cocina / Lavandería'],
	},
};
const sliderStore = createSlice({
	name: 'inicio',
	initialState,
	reducers: {
		updateInfMain: (state, value) => {
			state.infTypology = value.payload;
		},
	},
});
export const { updateInfMain } = sliderStore.actions;
export default sliderStore.reducer;
