import Icon from '../../../components/UI/Icon';
const iconLoader = require('../../../Assets/images/inicio/iconos/tour-360.json');
export const SectionRecorrido = () => {
	const properties = {
		loop: true,
		autoplay: true,
		animationData: iconLoader,
		speed: 0.7,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};
	return (
		<div className='section-recorrido page-video'>
			<h1 className='title'>
				<span className='line'>EXPLORA TU FUTURO </span>
				<span className='line'>
					DEPA DE <img className='otro_nivel' src={require('../../../Assets/images/inicio/main/santabeatriz/backgrounds/otro_nivel.png')} alt=''></img>
				</span>
			</h1>
			<a href='https://praux3d.com/padovainmobiliaria/helio/' target='_blank' className='recorrido'>
				<img className='img' src={require('../../../Assets/images/inicio/main/santabeatriz/backgrounds/compress-couple_1.webp')} alt=''></img>
				<div className='content-video'>
					<div className='icon-recorrido-lottie'>
						<Icon properties={properties}></Icon>
					</div>
					<img className='img-fluid' src={require('../../../Assets/images/inicio/main/santabeatriz/backgrounds/background-recorrido-virtual.png')}></img>
				</div>
			</a>
		</div>
	);
};
