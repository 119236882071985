import { createSlice } from '@reduxjs/toolkit'


const  initialState = {
    activeLinkValue: 0,
    navShow:true,
    links:[
        {
            index:5,
            title:"Login",
            link:"/login",
            // component:<Login></Login>
        },
        {
            index:6,
            title:"Registro",
            link:"/registro",
            // component:<LoginRegister></LoginRegister>,
            show:false,
        },      
    ]
}
export const routesFeatures = createSlice({
    name:"routes",
    initialState,
    reducers:{
        activeLinks: (state,value)=>{
            state.activeLinkValue = value.payload
        },
        hideNav:(state)=>{
            state.navShow = false
        },
        showNav:(state)=>{
            state.navShow = true
        },
    }
})

export const {activeLinks, hideNav, showNav} = routesFeatures.actions
export default routesFeatures.reducer