import React, { Component } from 'react';
import Modal from '../modal/modal';
import './libroDeReclamacion.scss';
import './promos.scss';

import $ from 'jquery';
export default class Posterga extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			imagen: require('../../Assets/images/inicio/render/1x1-construccion.png'),
			imagen2: require('../../Assets/images/inicio/iconos/apoderate-popup.png'),
			none: '',
		};
	}
	componentDidMount() {
		const fInicio = new Date('2021-01-01T00:00:00');
		const date = new Date();
		$('#img-terminos').click(function () {
			window.location.href = '/#section-contacto';
		});
	}
	activeModal(e, modal) {
		this.setState({ active: !this.state.active });
		console.log(e);
		document.querySelector(e).classList.add('active');
	}
	closeModal(e) {
		if (e.target.dataset.modal == 'modal') {
			this.setState({ active: !this.state.active });
			$('.content-modal').removeClass('active');
		}
	}
	render() {
		return (
			<main className='notas-promociones'>
				<h1 className='text-center'>Términos y condiciones</h1>
				<div className='nota'>
					<div className='img'>
						<img id='img-terminos' src={this.state.imagen}></img>
					</div>
					<div className='container-button' id='button'>
						<div>
							<div className='notas-promo'>
								<div className='promo'>
									{/* <div className={"item "+this.state.none}>
                                    <div className="title">Promo: Posterga tus cuotas pero no tus sueños</div>
                                    <div className="open-modal" onClick={this.activeModal.bind(this,".modal-1")}>Ver condiciones</div>
                                </div>  */}
									{/* <div className="item">
                                    <div className="title">Promo: 1 Dorm desde S/ 1,230</div>
                                    <div className="open-modal" onClick={this.activeModal.bind(this,".modal-2")}>Ver condiciones</div>
                                </div> */}
									<div className='item'>
										<div className='title'>Cuota 2 Dorms desde S/ 1,850 al mes</div>
										<div className='open-modal' onClick={this.activeModal.bind(this, '.modal-3')}>
											Ver condiciones
										</div>
									</div>
									<div className='item'>
										<div className='title'>Cuota 3 Dorms desde S/ 2,250 al mes</div>
										<div className='open-modal' onClick={this.activeModal.bind(this, '.modal-4')}>
											Ver condiciones
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className={`modal ${this.state.active ? 'active' : ' '}`} data-modal='modal' onClick={this.closeModal.bind(this)}>
						<div className='content-modal modal-1'>
							<div className='content-close' data-modal='modal' onClick={this.closeModal.bind(this)}>
								<div className='close' data-modal='modal'></div>
							</div>
							<h2 className='title-modal Secondary'>Promo: Posterga tus cuotas pero no tus sueños</h2>
							<div className='scroll'>
								<p className='paragraph'>
									(*) “Paga el 5% y empieza a pagar tu crédito hipotecario en Noviembre 2022”: Válido para separaciones realizadas desde el 01/12/2020 hasta el 31/12/2020 en el
									proyecto Helio y cuya firma de minuta de compraventa sea máximo hasta el 31/12/2020. Stock máximo de cinco unidades (05). El 5% del precio de venta total se deberá
									pagar a la firma de la minuta de compraventa. El saldo de la inicial será coordinado con la Inmobiliaria y, el mes donde se empieza a desembolsar el crédito
									hipotecario es Noviembre 2022. El valor de la cuota inicial depende de las condiciones del banco y valor del departamento. No acumulable con otras promociones.
								</p>
								<p className='paragraph'>
									(**) “Gana Giftcard S/3,000”: Válido para separaciones del 01/12/2020 hasta el 31/12/2020 en el proyecto Helio. Giftcard valorizada en S/3,000 para compras en
									Tiendas Ripley S.A. Será entregada al desembolso del crédito hipotecario. Stock máximo de cinco unidades (05). No acumulable con otras promociones.
								</p>
								<p className='paragraph'>
									Inversiones Padova S.A.C (Ruc No.20523824598) es un proveedor inmobiliario que inició sus actividades en el año 2010 quien es titular de la marca Padova
									Inmobiliaria. A la fecha ha desarrollado más de 05 proyectos a nivel nacional. El proyecto inmobiliario Helio se desarrolla por la empresa Inversiones Saletti S.A.C
									(Ruc No.20604452440) con domicilio en Jr. Las Poncianas N°139, Int.201, La Molina, que forma parte del Grupo Padova Inmobiliaria. Las características del proyecto
									son las contenidas en la memoria descriptiva y lista de acabados. El proyecto Inmobiliario cumple con la condición sismo resistente según norma técnica vigente.
									Para los fines pertinentes, se le informa de la existencia de: (a) Central de Información de Promotores Inmobiliarios y/o Empresas Constructoras de Unidades
									Inmobiliarias del Ministerio de Vivienda, Construcción y Saneamiento; (b) Registro de infracciones y sanciones del INDECOPI y (c) Portal Mira A Quien le Compras.
									Cualquier consulta, queja o reclamo sírvase comunicar al correo servicioalcliente@padovasac.com además de tener a su disposición el Libro de Reclamaciones en la
									sala de ventas del proyecto. Mayor información sobre el proceso de compra según Ley N° 29571 y/o experiencia, consulte www.padova.pe. Imágenes, vistas y planos
									referenciales sujetos a cambio según diseño del proyecto.
								</p>
							</div>
						</div>
						<div className='content-modal modal-4'>
							<div className='content-close' data-modal='modal' onClick={this.closeModal.bind(this)}>
								<div className='close' data-modal='modal'></div>
							</div>
							<h2 className='title-modal Secondary'>Cuota 3 Dorms desde S/ 2,250 al mes</h2>
							<div className='scroll'>
								<p className='paragraph'>
									(*) “3 dorms desde S/2,250”: Cuota basada en una simulación de crédito considerando un plazo de 20 años y, cuotas simples con 10% de cuota inicial a una tasa
									referencial de 7.5% aproximadamente. Dicha simulación se ha considerado para los departamentos de la tipología x02 de 65.45 m2 del proyecto Helio. Cuota propuesta
									es referencial, sujeta a evaluación y aprobación del cliente por parte de la entidad bancaria. Para mayor información consultar con nuestros asesores comerciales.{' '}
								</p>
								<p className={'paragraph '}>
									Inversiones Padova S.A.C (Ruc No.20523824598) es un proveedor inmobiliario que inició sus actividades en el año 2010 quien es titular de la marca Padova
									Inmobiliaria. A la fecha ha desarrollado más de 05 proyectos a nivel nacional. El proyecto inmobiliario Helio se desarrolla por la empresa Inversiones Saletti S.A.C
									(Ruc No.20604452440) con domicilio en Jr. Las Poncianas N°139, Int.201, La Molina, que forma parte del Grupo Padova Inmobiliaria. Las características del proyecto
									son las contenidas en la memoria descriptiva y lista de acabados. El proyecto Inmobiliario cumple con la condición sismo resistente según norma técnica vigente.
									Para los fines pertinentes, se le informa de la existencia de: (a) Central de Información de Promotores Inmobiliarios y/o Empresas Constructoras de Unidades
									Inmobiliarias del Ministerio de Vivienda, Construcción y Saneamiento; (b) Registro de infracciones y sanciones del INDECOPI y (c) Portal Mira A Quien le Compras.
									Cualquier consulta, queja o reclamo sírvase comunicar al correo <a href='mailto:servicioalcliente@padovasac.com'>servicioalcliente@padovasac.com</a> además de tener
									a su disposición el Libro de Reclamaciones en la sala de ventas del proyecto. Mayor información sobre el proceso de compra según Ley N° 29571 y/o experiencia,
									consulte <a href='https://www.padova.pe'>www.padova.pe.</a> Imágenes, vistas y planos referenciales sujetos a cambio según diseño del proyecto.
								</p>
							</div>
						</div>
						<div className='content-modal modal-3'>
							<div className='content-close' data-modal='modal' onClick={this.closeModal.bind(this)}>
								<div className='close' data-modal='modal'></div>
							</div>
							<h2 className='title-modal Secondary'>Cuota 2 Dorms desde S/ 1,850 al mes</h2>
							<div className='scroll'>
								<p className='paragraph'>
									(*) “2 dorms desde S/1,850”: Cuota basada en una simulación de crédito considerando un plazo de 20 años y, cuotas simples con 10% de cuota inicial a una tasa
									referencial de 7.5% aproximadamente. Dicha simulación se ha considerado para los departamentos de la tipología x03 de 52.80 m2 del proyecto Helio. Cuota propuesta
									es referencial, sujeta a evaluación y aprobación del cliente por parte de la entidad bancaria. Para mayor información consultar con nuestros asesores comerciales.
								</p>
								<p className={'paragraph '}>
									Inversiones Padova S.A.C (Ruc No.20523824598) es un proveedor inmobiliario que inició sus actividades en el año 2010 quien es titular de la marca Padova
									Inmobiliaria. A la fecha ha desarrollado más de 05 proyectos a nivel nacional. El proyecto inmobiliario Helio se desarrolla por la empresa Inversiones Saletti S.A.C
									(Ruc No.20604452440) con domicilio en Jr. Las Poncianas N°139, Int.201, La Molina, que forma parte del Grupo Padova Inmobiliaria. Las características del proyecto
									son las contenidas en la memoria descriptiva y lista de acabados. El proyecto Inmobiliario cumple con la condición sismo resistente según norma técnica vigente.
									Para los fines pertinentes, se le informa de la existencia de: (a) Central de Información de Promotores Inmobiliarios y/o Empresas Constructoras de Unidades
									Inmobiliarias del Ministerio de Vivienda, Construcción y Saneamiento; (b) Registro de infracciones y sanciones del INDECOPI y (c) Portal Mira A Quien le Compras.
									Cualquier consulta, queja o reclamo sírvase comunicar al correo <a href='mailto:servicioalcliente@padovasac.com'>servicioalcliente@padovasac.com</a> además de tener
									a su disposición el Libro de Reclamaciones en la sala de ventas del proyecto. Mayor información sobre el proceso de compra según Ley N° 29571 y/o experiencia,
									consulte <a href='https://www.padova.pe'>www.padova.pe</a>. Imágenes, vistas y planos referenciales sujetos a cambio según diseño del proyecto.
								</p>
							</div>
						</div>
						<div className='content-modal modal-2'>
							<div className='content-close' data-modal='modal' onClick={this.closeModal.bind(this)}>
								<div className='close' data-modal='modal'></div>
							</div>
							<h2 className='title-modal Secondary'>Promo: 1 Dorm desde S/ 1,230</h2>
							<div className='scroll'>
								<p className='paragraph'>
									(*) “1 dorm desde S/1,230”: Cuota basada en una simulación de crédito considerando un plazo de 20 años y, cuotas simples con 10% de cuota inicial a una tasa
									referencial de 7.7% aproximadamente. Dicha simulación se ha considerado para los departamentos de la tipología x04 de 37.17 m2 del proyecto Helio. Cuota sujeta a
									variaciones de precio. Para mayor información consultar con nuestros asesores comerciales.
								</p>
								<p className={'paragraph '}>
									Inversiones Padova S.A.C (Ruc No.20523824598) es un proveedor inmobiliario que inició sus actividades en el año 2010 quien es titular de la marca Padova
									Inmobiliaria. A la fecha ha desarrollado más de 05 proyectos a nivel nacional. El proyecto inmobiliario Helio se desarrolla por la empresa Inversiones Saletti S.A.C
									(Ruc No.20604452440) con domicilio en Jr. Las Poncianas N°139, Int.201, La Molina, que forma parte del Grupo Padova Inmobiliaria. Las características del proyecto
									son las contenidas en la memoria descriptiva y lista de acabados. El proyecto Inmobiliario cumple con la condición sismo resistente según norma técnica vigente.
									Para los fines pertinentes, se le informa de la existencia de: (a) Central de Información de Promotores Inmobiliarios y/o Empresas Constructoras de Unidades
									Inmobiliarias del Ministerio de Vivienda, Construcción y Saneamiento; (b) Registro de infracciones y sanciones del INDECOPI y (c) Portal Mira A Quien le Compras.
									Cualquier consulta, queja o reclamo sírvase comunicar al correo <a href='mailto:servicioalcliente@padovasac.com'>servicioalcliente@padovasac.com</a> además de tener
									a su disposición el Libro de Reclamaciones en la sala de ventas del proyecto. Mayor información sobre el proceso de compra según Ley N° 29571 y/o experiencia,
									consulte <a href='https://www.padova.pe'>www.padova.pe.</a> Imágenes, vistas y planos referenciales sujetos a cambio según diseño del proyecto.
								</p>
							</div>
						</div>

						<div className='content-modal modal-5'>
							<div className='content-close' data-modal='modal' onClick={this.closeModal.bind(this)}>
								<div className='close' data-modal='modal'></div>
							</div>
							<h2 className='title-modal Secondary'>Conquista tus sueños - 1 Dorm. desde S/ 1, 230 al mes</h2>
							<div className='scroll'>
								<p className='paragraph'>
									(*) “1 dorm desde S/1,230”: Cuota basada en una simulación de crédito considerando un plazo de 20 años y, cuotas simples con 10% de cuota inicial a una tasa
									referencial de 7.7% aproximadamente. Dicha simulación se ha considerado para los departamentos de la tipología x04 de 37.17 m2 del proyecto Helio. Cuota propuesta
									es referencial, sujeta a evaluación y aprobación del cliente por parte de la entidad bancaria. Para mayor información consultar con nuestros asesores comerciales.
								</p>
								<p className='paragraph'>
									(**) “Descuento de hasta S/25,756”: Válido para separaciones realizadas desde el 01/02/2021 hasta el 15/03/2021 en el proyecto Helio y cuya firma de minuta de
									compraventa sea máximo hasta el 15/03/2021. Dicho descuento hace referencia a los departamentos de la tipología x01 de 68.51 m2 del piso 21 al 24. Stock máximo de
									cuatro unidades (04). Consultar descuentos de los otros departamentos con nuestros asesores comerciales. No acumulable con otras promociones.
								</p>
								<p className='paragraph'>
									Inversiones Padova S.A.C (Ruc No.20523824598) es un proveedor inmobiliario que inició sus actividades en el año 2010 quien es titular de la marca Padova
									Inmobiliaria. A la fecha ha desarrollado más de 05 proyectos a nivel nacional. El proyecto inmobiliario Helio se desarrolla por la empresa Inversiones Saletti S.A.C
									(Ruc No.20604452440) con domicilio en Jr. Las Poncianas N°139, Int.201, La Molina, que forma parte del Grupo Padova Inmobiliaria. Las características del proyecto
									son las contenidas en la memoria descriptiva y lista de acabados. El proyecto Inmobiliario cumple con la condición sismo resistente según norma técnica vigente.
									Para los fines pertinentes, se le informa de la existencia de: (a) Central de Información de Promotores Inmobiliarios y/o Empresas Constructoras de Unidades
									Inmobiliarias del Ministerio de Vivienda, Construcción y Saneamiento; (b) Registro de infracciones y sanciones del INDECOPI y (c) Portal Mira A Quien le Compras.
									Cualquier consulta, queja o reclamo sírvase comunicar al correo <a href='mailto:servicioalcliente@padovasac.com'>servicioalcliente@padovasac.com</a> además de tener
									a su disposición el Libro de Reclamaciones en la sala de ventas del proyecto. Mayor información sobre el proceso de compra según Ley N° 29571 y/o experiencia,
									consulte <a href='https://www.padova.pe'>www.padova.pe</a>. Imágenes, vistas y planos referenciales sujetos a cambio según diseño del proyecto.
								</p>
							</div>
						</div>
						<div className='content-modal modal-6'>
							<div className='content-close' data-modal='modal' onClick={this.closeModal.bind(this)}>
								<div className='close' data-modal='modal'></div>
							</div>
							<h2 className='title-modal Secondary'>Cuota 2 dorms desde S/1,950 al mes</h2>
							<div className='scroll'>
								<p className='paragraph'>
									(*) “2 dorms desde S/1,950”: Cuota basada en una simulación de crédito considerando un plazo de 20 años y, cuotas simples con 10% de cuota inicial a una tasa
									referencial de 7.5% aproximadamente. Dicha simulación se ha considerado para los departamentos de la tipología x03 de 52.80 m2 del proyecto Helio. Cuota propuesta
									es referencial, sujeta a evaluación y aprobación del cliente por parte de la entidad bancaria. Para mayor información consultar con nuestros asesores comerciales.
								</p>
								<p className='paragraph'>
									Inversiones Padova S.A.C (Ruc No.20523824598) es un proveedor inmobiliario que inició sus actividades en el año 2010 quien es titular de la marca Padova
									Inmobiliaria. A la fecha ha desarrollado más de 05 proyectos a nivel nacional. El proyecto inmobiliario Helio se desarrolla por la empresa Inversiones Saletti S.A.C
									(Ruc No.20604452440) con domicilio en Jr. Las Poncianas N°139, Int.201, La Molina, que forma parte del Grupo Padova Inmobiliaria. Las características del proyecto
									son las contenidas en la memoria descriptiva y lista de acabados. El proyecto Inmobiliario cumple con la condición sismo resistente según norma técnica vigente.
									Para los fines pertinentes, se le informa de la existencia de: (a) Central de Información de Promotores Inmobiliarios y/o Empresas Constructoras de Unidades
									Inmobiliarias del Ministerio de Vivienda, Construcción y Saneamiento; (b) Registro de infracciones y sanciones del INDECOPI y (c) Portal Mira A Quien le Compras.
									Cualquier consulta, queja o reclamo sírvase comunicar al correo <a href='mailto:servicioalcliente@padovasac.com'>servicioalcliente@padovasac.com</a> además de tener
									a su disposición el Libro de Reclamaciones en la sala de ventas del proyecto. Mayor información sobre el proceso de compra según Ley N° 29571 y/o experiencia,
									consulte <a href='https://www.padova.pe.'>www.padova.pe.</a> Imágenes, vistas y planos referenciales sujetos a cambio según diseño del proyecto.
								</p>
							</div>
						</div>
						<div className='content-modal modal-7'>
							<div className='content-close' data-modal='modal' onClick={this.closeModal.bind(this)}>
								<div className='close' data-modal='modal'></div>
							</div>
							<h2 className='title-modal Secondary'>Cuota 3 dorms desde S/2,350 al mes</h2>
							<div className='scroll'>
								<p className='paragraph'>
									(*) “3 dorms desde S/2,350”: Cuota basada en una simulación de crédito considerando un plazo de 20 años y, cuotas simples con 10% de cuota inicial a una tasa
									referencial de 7.5% aproximadamente. Dicha simulación se ha considerado para los departamentos de la tipología x02 de 65.45 m2 del proyecto Helio. Cuota propuesta
									es referencial, sujeta a evaluación y aprobación del cliente por parte de la entidad bancaria. Para mayor información consultar con nuestros asesores comerciales.
								</p>
								<p className={'paragraph '}>
									Inversiones Padova S.A.C (Ruc No.20523824598) es un proveedor inmobiliario que inició sus actividades en el año 2010 quien es titular de la marca Padova
									Inmobiliaria. A la fecha ha desarrollado más de 05 proyectos a nivel nacional. El proyecto inmobiliario Helio se desarrolla por la empresa Inversiones Saletti S.A.C
									(Ruc No.20604452440) con domicilio en Jr. Las Poncianas N°139, Int.201, La Molina, que forma parte del Grupo Padova Inmobiliaria. Las características del proyecto
									son las contenidas en la memoria descriptiva y lista de acabados. El proyecto Inmobiliario cumple con la condición sismo resistente según norma técnica vigente.
									Para los fines pertinentes, se le informa de la existencia de: (a) Central de Información de Promotores Inmobiliarios y/o Empresas Constructoras de Unidades
									Inmobiliarias del Ministerio de Vivienda, Construcción y Saneamiento; (b) Registro de infracciones y sanciones del INDECOPI y (c) Portal Mira A Quien le Compras.
									Cualquier consulta, queja o reclamo sírvase comunicar al correo <a href='servicioalcliente@padovasac.com'>servicioalcliente@padovasac.com</a> además de tener a su
									disposición el Libro de Reclamaciones en la sala de ventas del proyecto. Mayor información sobre el proceso de compra según Ley N° 29571 y/o experiencia, consulte{' '}
									<a href='https://www.padova.pe'>www.padova.pe.</a> Imágenes, vistas y planos referenciales sujetos a cambio según diseño del proyecto.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class='line container'></div>
				<div className='nota'>
					<div className='img'>
						<img id='img-terminos' src={this.state.imagen2}></img>
					</div>
					<div className='container-button' id='button'>
						<div>
							<div className='notas-promo'>
								<div className='promo'>
									{/* <div className="item">
                                    <div className="title">Conquista tus sueños - 1 Dorm. desde S/ 1, 230 al mes </div>
                                    <div className="open-modal" onClick={this.activeModal.bind(this,".modal-5")}>Ver condiciones</div>
                                </div> */}
									<div className='item'>
										<div className='title'>Cuota 2 dorms desde S/1,950 al mes</div>
										<div className='open-modal' onClick={this.activeModal.bind(this, '.modal-6')}>
											Ver condiciones
										</div>
									</div>
									<div className='item'>
										<div className='title'>Cuota 3 dorms desde S/2,350 al mes</div>
										<div className='open-modal' onClick={this.activeModal.bind(this, '.modal-7')}>
											Ver condiciones
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		);
	}
}
