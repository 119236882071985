import OwlCarousel from 'react-owl-carousel2';
import { options2 } from '../../config';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

const ContextSlider = createContext();
export const SliderArea = ({ images, icon }) => {
	const [index, setIndex] = useState(0);
	console.log(images);
	const onChanged = useCallback(e => {
		setIndex(e.page.index == -1 ? 0 : e.page.index);
	}, []);
	useEffect(() => {}, [index]);
	return (
		<ContextSlider.Provider value={{ index }}>
			<div className='b-white content-owlcarousel'>
				<SliderComponent images={images} onChanged={onChanged} />
			</div>
			<div className='bg-white _idx'>
				<div className='title-slider idx_title_slider'>
					{icon && <div className={`icon mask ${images[index].icon}`}></div>}
					<TitleSlider data={images} />
				</div>
				<div className='idx_point'>
					{images &&
						images.map((item, idx) => {
							return <div className={`dot ${idx === index ? 'active' : ''}`} key={idx + 'idx-areas'}></div>;
						})}
				</div>
			</div>
		</ContextSlider.Provider>
	);
};
const TitleSlider = ({ data }) => {
	const { index } = useContext(ContextSlider);
	return <span className='title text-white'>{data[index]?.title || ''}</span>;
};

const SliderComponent = React.memo(({ images, onChanged }) => (
	<OwlCarousel options={options2} events={{ onChanged }} className='owl-carousel MyCarouselHorizontal owl-theme owl-loaded owl-drag' id='carousel-comunes'>
		{images.map((item, index) => (
			<div className='content-slide-interiores' data-src={item.img} href={item.imgDesktop} key={index + 'comunes'}>
				<div className='slide-interiores'>
					<div className='content-img'>
						<picture className='expand-img'>
							<source media='(min-width: 740px)' srcSet={item.img}></source>
							<img src={item.img} alt={`slide-${index}`}></img>
						</picture>
					</div>
					<div className='idx_mobile idx_sub_title'>
						<img className='idx_part-top' src={require('../../../Assets/images/icons/icon-part-top.svg').default}></img>
						{images[index].icon && <div className={`icon mask ${images[index].icon}`}></div>}
						{item.title}
					</div>
				</div>
			</div>
		))}
	</OwlCarousel>
));

export default SliderComponent;
