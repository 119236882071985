import React, { Component } from 'react';
import './whatsapp.scss';
import Swal from 'sweetalert2';
import { FormContainer, checkableBoolProps, setInputProps } from '../common/forms/Form';
import { BaseValidatonSchema } from '../common/forms/constraints/ValidatonSchemas';
import $ from '../../library/library';
import { InputChecked } from '../../Pages/main/floatForm/inputChecked';

export default class Whatsapp extends Component {
	constructor(props) {
		super(props);
		this.submitForm = this.props.submitForm;
		this.state = {
			show: false,
		};
	}
	initialValues = {
		fname: '',
		lname: '',
		email: '',
		telephone: '',
		terms: false,
	};

	state = {
		wpp: true,
	};

	componentDidMount() {
		var optionsMov = [{ section: '.footer', signal: '#svg1', class: '', nav: -200, style: 'transition:.2s;transform:scale(0)' }];
		function DetectSecction(options) {
			if (document.querySelector('.form')) {
				options.forEach(options => {
					if ($('.content-whatsapp').offset().top > $('#section-contacto').offset().top) {
						$('.content-whatsapp').attr('transform', 'scale(0)');
						document.querySelector('#svg1').style = 'transform:scale(0);transition:0.1s;animation:none';
					} else {
						document.querySelector('#svg1').style = '';
					}
				});
			}
		}
		$().windowScroll(function () {
			DetectSecction(optionsMov);
		});
	}
	whatsapp() {
		this.setState({
			show: !this.state.show,
		});
		window.dataLayer &&
			window.dataLayer.push({
				event: 'gaEvent',
				eventCategory: 'Whatsapp - Botón',
				eventAction: 'Clic',
				eventValue: 3,
			});
	}
	render() {
		return (
			<div className='items contacto'>
				<div className={this.state.show ? 'svg wpp active' : 'svg wpp'} id='svg1' data-active='false' onClick={this.whatsapp.bind(this)}>
					<div className='content-svg'>
						<img className='' id='whatsapp' data-active='true' src={require('../../Assets/images/inicio/iconos/botton-whatsapp.png')}></img>
					</div>
					<div className='ad'>Cotiza tu depa</div>
				</div>
				<div className={this.state.show ? 'field active' : 'field'}>
					<FormContainer initialValues={this.initialValues} validationSchema={BaseValidatonSchema} onSubmit={this.submitForm}>
						{form => {
							const { handleSubmit, isSubmitting } = form;

							return (
								<form className='content-contacto-whatsapp' onSubmit={handleSubmit}>
									<div className='close' onClick={this.whatsapp.bind(this)}></div>
									<div className='content-formulario-contacto '>
										<span className='Secondary --cercado'>
											¡Cotiza tu <span className='bold'>nuevo depa</span> aquí!
										</span>
										<span className='Secondary --santa-beatriz'>¡Habla con un asesor!</span>
										<input {...setInputProps('fname', 'input-contacto', form)} placeholder='Nombre*'></input>
										<input {...setInputProps('lname', 'input-contacto', form)} placeholder='Apellidos*'></input>
										<input {...setInputProps('telephone', 'input-contacto', form)} placeholder='Teléfonos / Celular*'></input>
										<input {...setInputProps('email', 'input-contacto', form)} placeholder='Correo electrónico*'></input>
										<div className='terminos flex-row'>
											<div className='mr-3'>
												<InputChecked form={form} name='terms' color='#f2a331'></InputChecked>
											</div>
											<p className='condiciones'>
												He leído y acepto la{' '}
												<a href='/politica-de-privacidad.pdf' target='_blank' className='secondary'>
													política de datos personales.
												</a>
											</p>
										</div>
										<button type='submit' className='btn-submit' disabled={isSubmitting}>
											{isSubmitting ? 'Enviando' : 'Enviar'}
										</button>
									</div>
									<div className='content-message d-none'>
										<span className='Secondary text-center d-block title'>¡Muchas gracias!</span>
										<span className='sub-title'>Un asesor te contactará pronto o puedes escribirle haciendo clic aquí</span>
										<a
											target='__blank'
											href='https://wa.me/51998160133?text=Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20el%20proyecto%20Helio'
											onClick={e => {
												window.dataLayer &&
													window.dataLayer.push({
														event: 'gaEvent',
														eventCategory: 'Whatsapp - Chat',
														eventAction: 'Clic',
														eventValue: 5,
													});
											}}
											className='asesores woman'
										>
											<div className='content-img-asesor mujer'>
												{/* <img className='' src={require('../../../assets/images/iconos/icono-woman.png')}></img> */}
												<div className='icon-user'></div>
												{/* <img className='' src={require('../../Assets/images/inicio/iconos/icono-man.png')}></img> */}
											</div>
											<div className='hover-link'>
												<span className='title-asesor'>Asesor Helio</span>
											</div>
										</a>
									</div>
								</form>
							);
						}}
					</FormContainer>
				</div>
			</div>
		);
	}
}
