import React, { Component, useCallback, useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import OwlCarousel from 'react-owl-carousel2';
import { updateInf, updateInfMain } from '../../../data/components/inicio';
import { events, optionTypology, options } from '../../config';
import sr from '../../../components/scrollreveal/scroll';
import $ from 'jquery';
const data = [
	{
		type: 1,
		data: [
			{
				img: require('../../../Assets/images/inicio/main/santabeatriz/tipologia/compress-tipox8.webp'),
				dorm: 1,
				name: 'Tipo x08',
				meters: 35.01,
				attr: ['Dormitorio Principal', 'Balcón', 'Baño', 'Sala / Comedor', 'Cocina / Lavandería'],
			},
		],
	},
	{
		type: 2,
		data: [
			{
				img: require('../../../Assets/images/inicio/main/santabeatriz/tipologia/compress-tipox03.webp'),
				name: 'Tipo x03',
				dorm: 2,
				meters: 51.61,
				attr: ['Dormitorio Principal', 'Balcón', 'Baño', 'Sala / Comedor', 'Cocina / Lavandería'],
			},
			{
				img: require('../../../Assets/images/inicio/main/santabeatriz/tipologia/compress-tipox05.webp'),
				name: 'Tipo x05',
				dorm: 2,
				meters: 43.97,
				attr: ['Dormitorio Principal', 'Balcón', 'Baño', 'Sala / Comedor', 'Cocina / Lavandería'],
			},
			{
				img: require('../../../Assets/images/inicio/main/santabeatriz/tipologia/compress-tipox10.webp'),
				name: 'Tipo x10',
				dorm: 2,
				meters: 44.43,
				attr: ['Dormitorio Principal', 'Balcón', 'Baño', 'Sala / Comedor', 'Cocina / Lavandería'],
			},
		],
	},
];

export const SectionTipology = props => {
	const [type, setType] = useState(1);
	const [typeIndex, setTypeIndex] = useState(0);
	const dataTipologia = data.filter(item => item.type == type)[0];
	const onChanged = useCallback(e => {
		setTypeIndex(e.page.index == -1 ? 0 : e.page.index);
	}, []);

	const changed = e => {
		setType(e);
		if (e != type) {
			setTypeIndex(0);
		}
	};
	function right(el) {
		el.classList.add('animation-right');
	}
	function left(el) {
		el.classList.add('animation-left');
	}
	useEffect(() => {
		sr.reveal('.globo-8', { opacity: 1, beforeReveal: right });
		sr.reveal('.globo-9', { opacity: 1, beforeReveal: right });
		sr.reveal('.globo-11', { opacity: 1, beforeReveal: left });
	}, []);
	return (
		<section className='Typology' id='section-tipologias'>
			<img className='globo-7 globe-left' src={require('../../../Assets/images/landing/globo-12.png')}></img>
			<img className='globo-8 globe-right' src={require('../../../Assets/images/landing/globo-13.png')}></img>

			<div className='content-title idx-content-title-typology'>
				<span className='title title-1 text-center d-block'>DEPARTAMENTOS CON</span>   
				<span className='title title-2 bold text-center d-block'>
					 ESPACIOS <img className='relative' src={require('../../../Assets/images/inicio/underline/unicos-santa-beatriz.png')}></img>
				</span>
			</div>
			<p className='paragraph '>¡Encuentra el departamento que se ajuste más a ti y a tus necesidades! El proyecto cuenta con diversas tipologías con depas de 1 y 2 dormitorios</p>
			<div className='content-tipology'>
				<div className='content-btn'>
					<div
						className={`btn-dorm btn-dorm-1 ${type == 1 ? 'active' : ''}`}
						onClick={e => {
							changed(1);
						}}
					>
						<div className='content-hover'>
							<span className='num'>1</span>
							<span className='dorm'>Dormitorio</span>
						</div>
					</div>
					<div
						className={`btn-dorm ${type == 2 ? 'active' : ''}`}
						onClick={e => {
							changed(2);
						}}
					>
						<div className='content-hover'>
							<span className='num'>2</span>
							<span className='dorm'>Dormitorios</span>
						</div>
					</div>
				</div>
				<SliderTypo data={dataTipologia} type={type} eventChange={onChanged} />
				<InfoTypo data={dataTipologia} typeIndex={typeIndex}></InfoTypo>
			</div>
		</section>
	);
};

const InfoTypo = ({ data, typeIndex }) => {
	const moveTo = (section, distance) => {
		window.scrollTo(0, document.querySelector(section).offsetTop - distance);
	};
	return (
		<div className='content-characteristic'>
			<div className='title-characteristic'>
				<span className='type secondary bold d-block'>{data.data[typeIndex].name}</span>
				<span className='dorms d-block'>{data.data[typeIndex].dorm + (data.data[typeIndex].dorm == 1 ? ' Dorm' : ' Dorms')}</span>
				<span className='meters d-block'>{data.data[typeIndex].meters + 'm²'}</span>
			</div>
			<div className='characteristic'>
				{data.data[typeIndex] &&
					data.data[typeIndex].attr &&
					data.data[typeIndex].attr.map((item, index) => {
						return (
							<span key={index} className='data'>
								{item}
							</span>
						);
					})}
			</div>
		</div>
	);
};
const SliderTypo = React.memo(({ data, eventChange, type }) => {
	const events = {
		onChanged: eventChange,
	};
	const ref = useRef();
	useEffect(() => {
		ref.current.goTo(0);
	}, [data, type]);
	return (
		<div className='content-img-typology '>
			<div className={`content-slider active`}>
				<OwlCarousel ref={ref} options={optionTypology} events={events} className='owl-carousel MyCarouselHorizontal owl-theme owl-loaded owl-drag' id='carousel-tipology'>
					{data.data.map((item, idx) => {
						return (
							<div className='img-slider' href={item.img} key={idx + 'tip'}>
								<img className='tipo active' src={item.img}></img>
							</div>
						);
					})}
				</OwlCarousel>
			</div>
			{/* <p className='paragraph'>*Planos, áreas y medidas referenciales. No incluye muebles decorativos.</p> */}
		</div>
	);
});
